import { Grid, InputAdornment } from '@mui/material'
import DateRangeIcon from '@mui/icons-material/DateRange'
import CheckIcon from '@mui/icons-material/Check'
import { useUser } from 'hooks'
import React, { useState, useEffect } from 'react'
import { AssignmentId, RoleId, StateCode, TypeCode } from 'types/ids'
import { IPerson } from 'types/userInterfaces'
import { InputField } from '../InputFields/InputField'
import PersonPicker from '../InputFields/PersonPicker'
import DatePicker from 'react-datepicker'
import { formatEndDate, formatStartDate, getDateFormatByLocaleAndResolution } from 'utils/utils'
import { useTranslation } from 'react-i18next'
import { IError } from 'types/error'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { allocationAPI } from 'api/AllocationAPI'
import { IAllocation, IAllocationTranslation } from 'types/allocationInterfaces'
import CaleoIconButton from '../IconButtons/CaleoIconButton'
import { chooseDBTranslation, createTranslation } from 'utils/translations'
import { useNotificationPopup } from 'Components/reusable/Notification'
import AllocationStateSwitch from './AllocationStateSwitch'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported  */
interface IAllocationInputProps {
  /** Role ID. */
  roleId?: number
  /** Assignment ID. */
  assignmentId?: number
  /** Start date. */
  start?: Date
  /** End date. */
  end?: Date | null
  /** Function to create or update allocation. */
  createOrUpdateAllocation?: (allocation: IAllocation) => void
  /** Allocation data. */
  allocation?: IAllocation
  /** Person options. */
  options?: IPerson[]
  /** Allocated person. */
  allocatedPerson?: IPerson
  /** Whether to show description. */
  showDescription?: boolean
  /** Allocation type. */
  type: TypeCode
}

/**
 * Allocation input controls.
 *
 * @returns Allocation input component.
 * @notExported
 */
const AllocationInputComponent: React.FC<IAllocationInputProps> = ({
  roleId,
  assignmentId,
  start,
  end,
  createOrUpdateAllocation,
  allocation,
  options,
  allocatedPerson,
  showDescription = false,
  type,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const { user } = useUser()
  const { t, i18n } = useTranslation()
  const { setSuccessNotificationPopup } = useNotificationPopup()

  const [person, setPerson] = useState<IPerson | null>(null)
  const [percent, setPercent] = useState<number | null>(100)
  const [startDate, setStartDate] = useState<Date | null>(start ? new Date(start) : new Date())
  const [endDate, setEndDate] = useState<Date | null>(end ? new Date(end) : new Date())
  const [confirmed, setConfirmed] = useState<boolean>(true)
  const [information, setInformation] = useState<string>('')
  const [numberError, setNumberError] = useState<string>()
  const [backendError, setBackendError] = useState<IError>()

  const format = getDateFormatByLocaleAndResolution(i18n.language)

  useEffect(() => {
    if (allocation) {
      if (allocation.Person) {
        setPerson(allocation.Person)
      } else if (allocatedPerson) setPerson(allocatedPerson)
      setPercent(allocation.percent)
      setStartDate(new Date(allocation.startDate))
      setEndDate(allocation.endDate ? new Date(allocation.endDate) : new Date())
      setInformation(chooseDBTranslation(i18n, allocation).information)
      setConfirmed(allocation.state ? true : false)
    }
  }, [allocation])

  const handleChange = (newValue: Date | null, start: boolean) => {
    if (newValue !== null) {
      if (start) {
        setStartDate(formatStartDate(newValue, 'day'))
      } else {
        setEndDate(formatEndDate(newValue, 'day'))
      }
    }
  }

  const submit = async () => {
    if (person && percent && startDate && endDate) {
      if (!percent || percent > 100 || percent < 1) {
        setNumberError(t('allocationPercentError'))
        return
      } else {
        setNumberError(undefined)
      }

      try {
        let result: IAllocation
        switch (type) {
          case 'other':
          case 'personal': {
            result = await allocationAPI.save({
              id: allocation?.id,
              state: (confirmed ? 1 : 0) as StateCode,
              percent,
              startDate,
              endDate,
              type,
              translations: [
                await createTranslation<IAllocationTranslation, 'allocationId'>(i18n.language, {
                  information: information,
                }),
              ],
            } as IAllocation)
            break
          }
          case 'project': {
            if (allocation) {
              result = await allocationAPI.save({
                ...allocation,
                state: (confirmed ? 1 : 0) as StateCode,
                percent,
                startDate,
                endDate,
                roleId: roleId as RoleId,
                assignmentId: assignmentId as AssignmentId,
                translations: [
                  await createTranslation<IAllocationTranslation, 'allocationId'>(i18n.language, {
                    information: information,
                  }),
                ],
              })
            } else {
              result = await allocationAPI.create({
                state: confirmed ? 1 : 0,
                percent,
                startDate,
                endDate,
                type: 'project',
                roleId,
                assignmentId,
                personId: person.id,
                translations: [
                  await createTranslation<IAllocationTranslation, 'allocationId'>(i18n.language, {
                    information: information,
                  }),
                ],
              })
            }
            break
          }
          default: {
            throw new Error('Invalid allocation type value')
          }
        }

        if (isComponentMounted.current) {
          setSuccessNotificationPopup()
          if (createOrUpdateAllocation) createOrUpdateAllocation(result)
          setPerson(null)
          setPercent(100)
          setStartDate(start ? new Date(start) : new Date())
          setEndDate(end ? new Date(end) : new Date())
          setConfirmed(true)
        }
      } catch (error) {
        setBackendError(error as IError)
      }
    }
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <Grid container alignItems="center" direction="row" spacing={1}>
      {showDescription && person && (
        <Grid item xs={12}>
          <InputField
            label={t('allocation.information')}
            fullWidth
            multiline
            rows={10}
            onChange={event => {
              setInformation(event.target.value as string)
            }}
            value={information}
          />
        </Grid>
      )}
      {user && options && (
        <>
          {type === 'project' && (
            <Grid item xs={12} md={4}>
              <PersonPicker
                value={person}
                onChange={newValue => {
                  if (newValue) {
                    setPerson(newValue)
                  }
                }}
                label=""
                disableClearable
                disabled={!!allocation}
                placeholder={t('allocationinputcomponent.personplaceholder')}
                options={options}
              />
            </Grid>
          )}
          {person && (
            <>
              <Grid item lg={2}>
                <InputField
                  onChange={e => setPercent(parseInt(e.target.value, 10))}
                  type="number"
                  value={percent}
                  error={numberError}
                  name="percentField"
                />
              </Grid>
              <Grid item lg={2}>
                <DatePicker
                  selected={startDate}
                  onChange={value => handleChange(value as Date | null, true)}
                  dateFormat={format}
                  customInput={
                    <InputField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <DateRangeIcon color="secondary" style={{ cursor: 'pointer' }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                  popperPlacement="bottom-start"
                  showMonthDropdown
                  useShortMonthInDropdown
                  showYearDropdown
                />
              </Grid>
              <Grid item lg={2}>
                <DatePicker
                  selected={endDate}
                  onChange={value => handleChange(value as Date | null, false)}
                  dateFormat={format}
                  customInput={
                    <InputField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <DateRangeIcon color="secondary" style={{ cursor: 'pointer' }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                  popperPlacement="bottom-start"
                  showMonthDropdown
                  useShortMonthInDropdown
                  showYearDropdown
                />
              </Grid>
              <Grid item lg={2}>
                <Grid container justifyContent="flex-end" alignItems="center" direction="row">
                  <Grid item>
                    <AllocationStateSwitch checked={confirmed} onChange={(_e, checked) => setConfirmed(checked)} />
                  </Grid>
                  <Grid item>
                    <CaleoIconButton
                      clickAction={() => submit()}
                      icon={<CheckIcon />}
                      valid={!!person && !!percent && !!startDate && !!endDate}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  )
}

export default AllocationInputComponent

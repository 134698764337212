import { Text, View } from '@react-pdf/renderer'
import colors from 'constants/colors'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IItemVisibility } from 'types/layoutInterfaces'
import { useStyles } from './styles'

/**
 * Renders the About component.
 *
 * @param {string} description - The description of the component.
 * @param {object} layout - The layout configuration object.
 * @param {string} headingColor - The color of the heading.
 * @return {JSX.Element} The rendered About component.
 * @notExported
 */
const About = ({ description, layout, headingColor }) => {
  const { i18n, t } = useTranslation()
  const styles = useStyles()

  const hidden = useMemo(() => {
    if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
      const visibilities = layout.ItemVisibilities.filter((visibility: IItemVisibility) => visibility.type === 'about')
      if (visibilities.length > 0) {
        return visibilities.every((value: IItemVisibility) => value.hidden === true)
      }
    }
  }, [layout, i18n.language])

  if (hidden) {
    return <></>
  } else {
    return (
      <>
        <View style={styles.container}>
          <Text style={[styles.title, { color: headingColor ? headingColor : colors.secondary }]}>
            {t('print.about.title')}
          </Text>
          <Text style={styles.description}>{description}</Text>
        </View>
      </>
    )
  }
}

export default React.memo(About)

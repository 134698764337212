import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid } from '@mui/material'
import { useLanguageData } from 'hooks/data'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ILanguageSkill } from 'types/cvsInterfaces'
import { IItemVisibility } from 'types/layoutInterfaces'
import { languageLevels } from 'utils/utils'
import SkillChip from '../SkillChip'
import VisibilityCheckbox from '../VisibilityCheckbox'
import colors from 'constants/colors'

/**
 * Renders the LanguageSkills component.
 *
 * @param {Object} languages - The list of languages.
 * @param {Object} layout - The layout configuration.
 * @param {boolean} hideSection - Indicates if the section should be hidden.
 * @param {boolean} hideItem - Indicates if the item should be hidden.
 * @param {boolean} expanded - Indicates if the accordion is expanded.
 * @param {function} setStatus - Sets the status of the accordion.
 * @return {JSX.Element} The rendered LanguageSkills component.
 * @notExported
 */
const LanguageSkills = ({ languages, layout, hideSection, hideItem, expanded, setStatus }) => {
  const { t } = useTranslation()
  const { languages: options } = useLanguageData()

  let hidden = false
  if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
    const visibilities = layout.ItemVisibilities.filter(
      (visibility: IItemVisibility) => visibility.type === 'languages'
    )
    if (visibilities.length > 0) {
      const visibilityMap = new Map(visibilities.map(item => [item.itemId, item.hidden]))
      hidden = languages.every(value => visibilityMap.get(value.id) === true)
    }
  }

  const native: ILanguageSkill[] = []
  const excellent: ILanguageSkill[] = []
  const good: ILanguageSkill[] = []
  const manageable: ILanguageSkill[] = []
  const basics: ILanguageSkill[] = []

  for (const language of languages) {
    if (language.level === 5) {
      native.push(language)
    }
    if (language.level === 4) {
      excellent.push(language)
    }
    if (language.level === 3) {
      good.push(language)
    }
    if (language.level === 2) {
      manageable.push(language)
    }
    if (language.level === 1) {
      basics.push(language)
    }
  }

  const getLanguageSection = (count, array) => {
    return (
      <>
        <Box display="flex" flexDirection="row">
          <Box fontWeight={'bold'}>{t(languageLevels()[count - 1].label)}</Box>
        </Box>
        <Box display="block" width="100%" my={2}>
          {array.map(language => {
            let hiddenItem = false
            if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
              const visibilities = layout.ItemVisibilities.filter(
                (visibility: IItemVisibility) => visibility.type === 'languages'
              )
              if (visibilities.length > 0) {
                hiddenItem = visibilities.find(
                  (value: IItemVisibility) => value.itemId === language.id && value.hidden === true
                )
              }
            }
            return (
              <SkillChip
                hidden={!!hiddenItem}
                key={language.id}
                name={options ? options[language.languageCode] : language.languageCode}
                itemId={language.id}
                type="languages"
                onClick={hideItem}
              />
            )
          })}
        </Box>
        {count > 1 && <Divider />}
      </>
    )
  }

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={(e, expanded) => setStatus(expanded)}
      elevation={0}
      sx={{ borderRadius: '5px', border: `1px solid ${colors.borderColor}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          fontSize: '20px',
          color: theme => theme.palette.primary.main,
          fontWeight: 'bold',
        }}
      >
        <Grid container alignItems="center">
          <Grid item>
            <VisibilityCheckbox checked={hidden} onChange={(checked: boolean) => hideSection(checked, 'languages')} />
          </Grid>
          <Grid item>{t('print.layout.languages')}</Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12}>
          {getLanguageSection(5, native)}
          {getLanguageSection(4, excellent)}
          {getLanguageSection(3, good)}
          {getLanguageSection(2, manageable)}
          {getLanguageSection(1, basics)}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default React.memo(LanguageSkills)

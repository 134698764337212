import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { layoutAPI } from 'api/LayoutAPI'
import { useNotificationPopup } from 'Components/reusable/Notification'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ICVData, ILayout } from 'types/layoutInterfaces'
import ControlSections from './ControlSections'
import { usePreviewData } from './PrintPreviewProvider'

/** @notExported */
interface ILayoutControlsProps {
  /** CV data */
  data: ICVData
  /** Function to set open */
  setOpen: (value: boolean) => void
}

/**
 * Layout controls component.
 *
 * @returns Layout controls component.
 * @notExported
 */
const LayoutControls: React.FC<ILayoutControlsProps> = ({ data, setOpen }) => {
  const { visibleLayout, setVisibleLayout } = usePreviewData()
  const { t } = useTranslation()
  if (!data || !visibleLayout) return <div>{t('print.noCV')}</div>
  const { setNotificationPopup } = useNotificationPopup()

  const setErrorNotification = () => {
    setNotificationPopup({
      message: t('error.header'),
      type: 'error',
      duration: 'long',
    })
  }

  const hideFromPreview = (checked: boolean, id: number, type: string) => {
    if (visibleLayout.ItemVisibilities?.find(item => item.type === type && item.itemId === id)) {
      setVisibleLayout(visibleLayout => ({
        ...visibleLayout,
        ItemVisibilities: [
          ...visibleLayout.ItemVisibilities.map(x => {
            if (x.itemId !== id) return x
            return { ...x, hidden: checked }
          }),
        ],
      }))
    } else {
      const newVisibility = { hidden: checked, itemId: id, type: type }
      setVisibleLayout({ ...visibleLayout, ItemVisibilities: [...visibleLayout.ItemVisibilities, newVisibility] })
    }
  }

  const updateLayout = (tempLayout: ILayout, type: string, item: { id: number }, checked: boolean) => {
    if (tempLayout.ItemVisibilities.find(visibility => visibility.type === type && visibility.itemId === item.id)) {
      tempLayout = {
        ...tempLayout,
        ItemVisibilities: [
          ...tempLayout.ItemVisibilities.map(visibility => {
            if (visibility.itemId !== item.id || visibility.type !== type) return visibility
            return { ...visibility, hidden: checked }
          }),
        ],
      }
    } else {
      const newVisibility = { hidden: checked, itemId: item.id, type: type }
      tempLayout.ItemVisibilities.push(newVisibility)
    }

    return tempLayout
  }

  const hideSection = (checked: boolean, sectionType: string) => {
    const items: number[] = []
    if (sectionType === 'about') {
      items.push(data.about.cvId)
      hideFromPreview(checked, data.about.cvId, sectionType)
    } else if (sectionType === 'education') {
      let tempLayout = _.cloneDeep(visibleLayout)
      const degrees: number[] = []
      const courses: number[] = []
      const certificates: number[] = []

      for (const item of data.degrees) {
        degrees.push(item.id)
        tempLayout = updateLayout(tempLayout, 'degrees', item, checked)
      }

      for (const item of data.courses) {
        courses.push(item.id)
        tempLayout = updateLayout(tempLayout, 'courses', item, checked)
      }

      for (const item of data.certificates) {
        certificates.push(item.id)
        tempLayout = updateLayout(tempLayout, 'certificates', item, checked)
      }

      setVisibleLayout(tempLayout)

      if (degrees.length > 0 && visibleLayout && visibleLayout.id > 0) {
        try {
          layoutAPI.hideSection(visibleLayout.id, degrees, 'degrees', checked)
        } catch {
          setErrorNotification()
        }
      }

      if (courses.length > 0 && visibleLayout && visibleLayout.id > 0) {
        try {
          layoutAPI.hideSection(visibleLayout.id, courses, 'courses', checked)
        } catch {
          setErrorNotification()
        }
      }

      if (certificates.length > 0 && visibleLayout && visibleLayout.id > 0) {
        try {
          layoutAPI.hideSection(visibleLayout.id, certificates, 'certificates', checked)
        } catch {
          setErrorNotification()
        }
      }
    } else {
      const section: { id: number }[] = data[sectionType]
      if (section) {
        let tempLayout = _.cloneDeep(visibleLayout)
        for (const item of section) {
          items.push(item.id)

          tempLayout = updateLayout(tempLayout, sectionType, item, checked)
        }
        setVisibleLayout(tempLayout)
      }
    }

    if (sectionType !== 'education') {
      if (items.length > 0 && visibleLayout && visibleLayout.id > 0) {
        try {
          layoutAPI.hideSection(visibleLayout.id, items, sectionType, checked)
        } catch {
          setErrorNotification()
        }
      } else if (visibleLayout && visibleLayout.id === 0) {
        setOpen(true)
      }
    }
  }

  const hideItem = (checked: boolean, id: number, type: string) => {
    if (visibleLayout && visibleLayout.id > 0) {
      hideFromPreview(checked, id, type)
      try {
        layoutAPI.hideItem(visibleLayout.id, id, type, checked)
      } catch {
        setErrorNotification()
      }
    } else {
      setOpen(true)
    }
  }

  const hideProfileImage = (status: boolean) => {
    if (visibleLayout && visibleLayout.id) {
      setVisibleLayout({ ...visibleLayout, hideProfileImage: status })
      try {
        layoutAPI.changeProfileImage(visibleLayout.id, status)
      } catch {
        setErrorNotification()
      }
    }
  }

  const hideName = async (status: boolean) => {
    if (visibleLayout && visibleLayout.id) {
      setVisibleLayout({ ...visibleLayout, hideName: status })
      try {
        await layoutAPI.changeNameHiding(visibleLayout.id, status)
      } catch {
        setErrorNotification()
      }
    }
  }

  const hideLastUsed = async (status: boolean) => {
    if (visibleLayout && visibleLayout.id) {
      setVisibleLayout({ ...visibleLayout, hideLastUsed: status })
      try {
        await layoutAPI.changeLastUsedHiding(visibleLayout.id, status)
      } catch {
        setErrorNotification()
      }
    }
  }

  const showContactInfo = (status: boolean) => {
    if (visibleLayout && visibleLayout.id) {
      setVisibleLayout({ ...visibleLayout, showContactInfo: status })
      try {
        layoutAPI.toggleContactInfo(visibleLayout.id, status)
      } catch {
        setErrorNotification()
      }
    }
  }

  const showCertificateExpiry = (status: boolean) => {
    if (visibleLayout && visibleLayout.id) {
      setVisibleLayout({ ...visibleLayout, showCertificateExpiry: status })
      try {
        layoutAPI.changeCertificateExpiry(visibleLayout.id, status)
      } catch {
        setErrorNotification()
      }
    }
  }

  return (
    <>
      <Box ml={1} mb={2}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={() => showContactInfo(!visibleLayout.showContactInfo)}
              checked={visibleLayout.showContactInfo}
            />
          }
          label={t('print.layout.showContactInfo')}
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={() => hideProfileImage(!visibleLayout.hideProfileImage)}
              checked={visibleLayout.hideProfileImage}
            />
          }
          label={t('print.hideProfileImage')}
        />
        <FormControlLabel
          control={<Checkbox onChange={() => hideName(!visibleLayout.hideName)} checked={visibleLayout.hideName} />}
          label={t('print.hideName')}
        />
        <FormControlLabel
          control={
            <Checkbox onChange={() => hideLastUsed(!visibleLayout.hideLastUsed)} checked={visibleLayout.hideLastUsed} />
          }
          label={t('print.hideLastUsed')}
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={() => showCertificateExpiry(!visibleLayout.showCertificateExpiry)}
              checked={visibleLayout.showCertificateExpiry}
            />
          }
          label={t('print.showCertificateExpiry')}
        />
      </Box>
      <ControlSections data={data} layout={visibleLayout} hideSection={hideSection} hideItem={hideItem} />
    </>
  )
}

export default LayoutControls

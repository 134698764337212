import { notificationAPI } from 'api/NotificationAPI'
import { useEffect, useState } from 'react'
import { IError } from 'types/error'
import { useUser } from './user'
import { INotification } from 'types/notificationInterfaces'
import { useLoading } from 'Components/reusable/LoadingProvider'
import { useIsComponentMounted } from './util'

/**
 * Hook for getting user notifications.
 *
 * @returns Hook variables and functions.
 */
export const useNotifications = () => {
  const isComponentMounted = useIsComponentMounted()
  const [notifications, setNotifications] = useState<INotification[]>([])
  const [unreadCount, setUnreadCount] = useState<number>(0)
  const [refresh, setRefresh] = useState<Date>()
  const { startLoading, endLoading } = useLoading()
  const [error, setError] = useState<IError>()

  const { user } = useUser()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (user) {
        try {
          startLoading('notificationsUsage')
          const data = await notificationAPI.getNotifications(controller)
          if (isComponentMounted.current) {
            setNotifications(data)
            setUnreadCount(data.filter(notification => notification.key && notification.key.includes('unread')).length)
          }
        } catch (error) {
          setError(error as IError)
        } finally {
          endLoading('notificationsUsage')
        }
      }
    })()

    return () => {
      controller.abort()
    }
  }, [user, refresh])

  return {
    notifications,
    unreadCount,
    setRefresh,
    error,
  }
}

import { Box, Grid, Popover, Tab, Tabs, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FileCopy, Info, PictureAsPdf, Star, StarBorder } from '@mui/icons-material'
import Autocomplete from '@mui/material/Autocomplete'
import { personAPI } from 'api/PersonAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { ToolbarItems } from 'Components/General/Toolbar'
import ProgressCircle from 'pages/Profile/ProfileToolbarItems/ProgressCircle'
import BackButton from 'Components/reusable/IconButtons/BackButton'
import CaleoIconButton from 'Components/reusable/IconButtons/CaleoIconButton'
import EditButton from 'Components/reusable/IconButtons/EditButton'
import { InputField } from 'Components/reusable/InputFields/InputField'
import { useUser } from 'hooks'
import { orderBy } from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ICv } from 'types/cvsInterfaces'
import { IError } from 'types/error'
import { IPerson } from 'types/userInterfaces'
import { convertToDisplayDate, fuseFiltering } from 'utils/utils'
import { chooseDBTranslation } from 'utils/translations'
import AssistantIcon from '@mui/icons-material/Assistant'
import ErrorIcon from '@mui/icons-material/Error'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import DeleteIcon from '@mui/icons-material/Delete'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import { useNotificationPopup } from 'Components/reusable/Notification'
import { ISalesPoolItem } from 'types/salesPoolInterfaces'
import { useSalesPool } from 'Components/General/SalesPoolProvider/SalesPoolProvider'
import { teamAPI } from 'api/TeamAPI'
import { ITeamMember } from 'types/teamInterfaces'
import colors from 'constants/colors'
import { CVOption } from '..'
import { networkAPI } from 'api/NetworkAPI'
import { useIsComponentMounted } from 'hooks/util'
import DeleteConfirm from 'Components/reusable/Confirmations/DeleteConfirm'
import { useNavigate, useLocation } from 'react-router-dom'

interface IProfileToolbarItemsProps {
  /** Callback function to set the copy state */
  setCopy: (value: boolean) => void
  /** Callback function to set the edit state */
  setEdit: (value: ICv) => void
  /** Callback function to set the progress modal state */
  setProgressModal: (value: boolean) => void
  /** Flag indicating if the CV is currently loading */
  cvLoading: boolean
  /** The CV updated date */
  cvUpdatedAt: Date
  /** The CV parse progress */
  profileProgress: number | undefined
  /** Callback function to set the CV loading state */
  setSelectedCV: (value: ICv) => void
  /** The selected CV object */
  selectedCV: ICv
  /** The person data object */
  personData: IPerson | undefined
  /** Callback function to set the person data */
  setPersonData: (value: IPerson) => void
  /** Flag indicating if the user has sales access */
  salesAccess: boolean
  /** Callback function to set the profile wizard state */
  setProfileWizard: (value: boolean) => void
  /** Callback function to set the open history state */
  setOpenHistory: (cvId: number) => void
  /** Callback function to set the CV parse state */
  setCvParse: (value: boolean) => void
  /** The CV options */
  CVOptions: CVOption[]
  /** The parse credits */
  parseCredits: number
  /** Function for updating CV list */
  updateCvList: (type: string, data: ICv) => void
}

/**
 * ProfileToolbarItems component.
 *
 * @param setCopy - Callback function to set the copy state
 * @param setEdit - Callback function to set the edit state
 * @param setProgressModal - Callback function to set the progress modal state
 * @param cvLoading - Flag indicating if the CV is currently loading
 * @param cvUpdatedAt - The CV updated date
 * @param profileProgress - The CV parse progress
 * @param selectedCV - The selected CV object
 * @param setSelectedCV - Callback function to set the CV loading state
 * @param personData - The person data object
 * @param setPersonData - Callback function to set the person data
 * @param salesAccess - Flag indicating if the user has sales access
 * @param setProfileWizard - Callback function to set the profile wizard state
 * @param setOpenHistory - Callback function to set the open history state
 * @param setCvParse - Callback function to set the CV parse state
 * @param CVOptions - The CV options
 * @param parseCredits - The parse credits
 * @returns The ProfileToolbarItems component.
 * @notExported
 */
const ProfileToolbarItems: React.FC<IProfileToolbarItemsProps> = ({
  setCopy,
  setEdit,
  setProgressModal,
  cvLoading,
  cvUpdatedAt,
  profileProgress,
  selectedCV,
  setSelectedCV,
  personData,
  setPersonData,
  salesAccess,
  setProfileWizard,
  setOpenHistory,
  setCvParse,
  CVOptions,
  parseCredits,
  updateCvList,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const { user, groups, features } = useUser()
  const { t, i18n } = useTranslation()
  const { editedSalesPool, setEditedSalesPool } = useSalesPool()
  const [backendError, setBackendError] = useState<IError>()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { setNotificationPopup } = useNotificationPopup()
  const [membersWithTeam, setMembersWithTeam] = useState<ITeamMember[]>()
  const [teamMemberValue, setTeamMemberValue] = useState<ITeamMember>()
  const [networkManagerAccess, setNetworkManagerAccess] = useState<boolean>(false)
  const [confirmation, setConfirmation] = useState<boolean>(false)

  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true })
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        const data = await teamAPI.getLeaderMembers(controller)
        setMembersWithTeam(orderBy(data, ['Team.id'], ['asc']))
        if (!isComponentMounted.current) return

        if (personData?.organizationId) {
          const manager = await networkAPI.isManagerForOrganization(personData.organizationId, controller)
          if (!isComponentMounted.current) return
          setNetworkManagerAccess(manager)
        }
      } catch (error) {
        setBackendError(error as IError)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const infoOpen = Boolean(anchorEl)

  const copyRule = () => {
    if (
      groups.includes('admin') ||
      salesAccess ||
      user?.Person?.id === selectedCV?.personId ||
      (user?.Person?.id === selectedCV?.personId && groups.includes('freelancer'))
    ) {
      return true
    }
    return false
  }

  const getCVUpdateStatus = selectedCV => {
    if (!selectedCV.LastEditor) {
      return convertToDisplayDate(cvUpdatedAt)
    } else {
      return `${convertToDisplayDate(cvUpdatedAt)} ${selectedCV.LastEditor?.Person?.firstName} ${
        selectedCV.LastEditor?.Person?.lastName
      }`
    }
  }

  const addToPool = () => {
    if (personData && 'id' in personData) {
      const newItem = {
        personId: personData.id,
        Person: personData,
      } as ISalesPoolItem

      if (
        editedSalesPool &&
        editedSalesPool.SalesPoolItems &&
        editedSalesPool.SalesPoolItems.length > 0 &&
        editedSalesPool.SalesPoolItems.find(item => item.personId === newItem.personId)
      ) {
        setNotificationPopup({
          message: t('salesPool.itemExists'),
          type: 'warning',
          duration: 'short',
        })
      } else {
        if (editedSalesPool && editedSalesPool.SalesPoolItems?.length) {
          setEditedSalesPool({
            ...editedSalesPool,
            SalesPoolItems: [...editedSalesPool.SalesPoolItems, newItem],
          })
        } else {
          setEditedSalesPool({
            title: '',
            anonymous: false,
            durationInWeeks: 4,
            allocationsVisible: false,
            publicId: undefined,
            SalesPoolItems: [newItem],
          })
        }

        setNotificationPopup({
          message: t('salesPool.itemAdded'),
          type: 'success',
          duration: 'short',
        })
      }
    }
  }

  const deleteItem = async (cvId: number) => {
    try {
      await personAPI.delete(cvId)
      updateCvList('delete', selectedCV)
      setConfirmation(false)
    } catch (error) {
      setBackendError(error as IError)
    }
  }

  return (
    <ToolbarItems>
      <Tabs
        value={false}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          maxHeight: '44px',
          margin: 0,
          padding: 0,
          '.MuiTabScrollButton-root': {
            maxHeight: '20px',
            maxWidth: '20px',
            background: colors.secondary,
            borderRadius: '20px',
            alignSelf: 'center',
          },
        }}
      >
        {location.state?.search && (
          <Tooltip title={t('profile.backButton')}>
            <Tab
              component="div"
              disableRipple
              sx={{
                maxHeight: '44px',
                margin: 0,
                padding: 0,
                minWidth: 0,
              }}
              label={<BackButton clickAction={() => navigate(-1)} />}
            />
          </Tooltip>
        )}
        {membersWithTeam && membersWithTeam?.length > 0 && personData && largeScreen && (
          <Tab
            component="div"
            sx={{
              maxHeight: '44px',
              margin: 0,
              padding: 0,
              minWidth: 0,
            }}
            disableRipple
            label={
              <Grid sx={{ display: 'flex', flexDirection: 'row', maxWidth: '100%', minWidth: '210px' }}>
                <Autocomplete
                  noOptionsText={t('controls.noOptionsText')}
                  options={membersWithTeam.filter(
                    member => member.Account.Person && member.Account.Person.id !== personData.id
                  )}
                  getOptionLabel={option => `${option.Account.Person?.firstName} ${option.Account.Person?.lastName}`}
                  value={teamMemberValue}
                  isOptionEqualToValue={(option, value) => {
                    if (value && option.id === value.id) {
                      return true
                    }
                    return false
                  }}
                  onChange={(e, value) => {
                    if (value && value.Account.Person) {
                      setTeamMemberValue(undefined)
                      navigate(`/profile/${value.Account.Person.id}`)
                    }
                  }}
                  renderInput={params => (
                    <InputField
                      {...params}
                      placeholder={t('profile.selectTeamMember.placeholder')}
                      variant="outlined"
                      name="teamMember"
                      fullWidth
                      sx={{
                        minWidth: '150px',
                        [theme.breakpoints.down('sm')]: {
                          bottom: '0px',
                        },
                        bottom: '15px',
                      }}
                    />
                  )}
                  groupBy={option => {
                    if (option && option.Team) {
                      return chooseDBTranslation(i18n, option.Team).name
                    }
                    return ''
                  }}
                  filterOptions={(options, { inputValue }) =>
                    fuseFiltering(options, inputValue, ['Account.Person.firstName', 'Account.Person.lastName'])
                  }
                  style={{ marginTop: '13%', width: '100%' }}
                  size="small"
                />
              </Grid>
            }
          />
        )}
        {selectedCV && !mobileView && (
          <Tab
            component="div"
            sx={{ maxHeight: '44px', margin: 0, padding: 0, minWidth: 0, textTransform: 'none' }}
            disableRipple
            label={
              <Grid container alignItems="center">
                <Tooltip title={t('profile.historyButton')}>
                  <Grid>
                    <CaleoIconButton
                      icon={<ErrorIcon fontSize="large" />}
                      clickAction={() => {
                        setOpenHistory(selectedCV.id)
                      }}
                    />
                  </Grid>
                </Tooltip>
                <Grid>
                  <Typography
                    sx={{
                      letterSpacing: '0px',
                      color: theme.palette.primary.main,
                      fontSize: '1rem',
                      fontFamily: 'Helvetica',
                      paddingTop: '0px',
                      whiteSpace: 'nowrap',
                      fontWeight: 'bold',
                    }}
                  >{`${t('profile.lastUpdate')}: `}</Typography>
                  <Typography
                    sx={{
                      fontWeight: 'normal',
                      letterSpacing: '0px',
                      color: theme.palette.primary.main,
                      fontSize: '1rem',
                      fontFamily: 'Helvetica',
                      paddingTop: '0px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {getCVUpdateStatus(selectedCV)}
                  </Typography>
                </Grid>
              </Grid>
            }
          />
        )}
        {!mobileView && (
          <Tab
            component="div"
            sx={{
              maxHeight: '44px',
              margin: 0,
              padding: 0,
              minWidth: 0,
            }}
            disableRipple
            label={
              <Grid
                sx={{
                  display: 'block',
                  backgroundColor: theme.palette.text.primary,
                  width: '1px',
                  marginLeft: '15px',
                  marginRight: '15px',
                  height: '43px',
                }}
              ></Grid>
            }
          />
        )}
        {CVOptions.length > 0 && (
          <Tab
            component="div"
            sx={{
              maxHeight: '44px',
              margin: 0,
              padding: 0,
              minWidth: 0,
            }}
            disableRipple
            label={
              <Grid container alignItems="center" spacing={1}>
                {!mobileView && (
                  <Grid item>
                    <Typography fontWeight="bold">{`${t('profile.selectedCV')}: `}</Typography>
                  </Grid>
                )}
                <Grid item>
                  <Autocomplete
                    autoSelect
                    sx={{
                      marginTop: '8px',
                      [theme.breakpoints.down('sm')]: {
                        marginTop: '0px',
                      },
                    }}
                    noOptionsText={t('controls.noOptionsText')}
                    disableClearable
                    options={CVOptions}
                    getOptionLabel={option => option.label}
                    value={CVOptions.find(option => option.data?.id === selectedCV.id)}
                    isOptionEqualToValue={(option, value) => {
                      if (value && option.data?.id === value.data?.id) {
                        return true
                      }
                      return false
                    }}
                    onChange={(e, value) => {
                      if (value && value.data) {
                        setSelectedCV(value.data)
                      }
                    }}
                    renderInput={params => (
                      <InputField
                        {...params}
                        name="type"
                        size="small"
                        variant="outlined"
                        sx={{
                          minWidth: '150px',
                          [theme.breakpoints.down('sm')]: {
                            bottom: '0px',
                          },
                          bottom: '15px',
                          marginTop: { sm: '18%', xs: 0 },
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            }
          />
        )}
        {CVOptions.length && copyRule() && !mobileView && (
          <Tab
            component="div"
            sx={{
              maxHeight: '44px',
              margin: 0,
              padding: 0,
              minWidth: 0,
            }}
            disableRipple
            label={
              <Grid container>
                <Grid>
                  <CaleoIconButton
                    clickAction={() => setCopy(true)}
                    tooltip={t('profile.copyCV')}
                    icon={<FileCopy fontSize="small" />}
                    size="xsmall"
                  />
                </Grid>
                {personData && selectedCV && (
                  <Grid>
                    <CaleoIconButton
                      clickAction={async () => {
                        if (selectedCV.id !== personData.defaultCvId) {
                          await personAPI.setDefaultCv(personData.id, selectedCV.id)
                          setPersonData((data =>
                            data ? { ...data, defaultCvId: selectedCV.id } : data) as unknown as IPerson)
                        }
                      }}
                      tooltip={
                        selectedCV.id === personData.defaultCvId ? t('profile.isDefault') : t('profile.setDefault')
                      }
                      icon={
                        selectedCV.id === personData.defaultCvId ? (
                          <Star fontSize="small" />
                        ) : (
                          <StarBorder fontSize="small" />
                        )
                      }
                      size="xsmall"
                    />
                  </Grid>
                )}
              </Grid>
            }
          />
        )}
        {CVOptions.length && selectedCV && !selectedCV.original && !mobileView && (
          <Tab
            component="div"
            sx={{
              maxHeight: '44px',
              margin: 0,
              padding: 0,
              minWidth: 0,
            }}
            disableRipple
            label={
              <Grid container>
                <Grid>
                  <CaleoIconButton
                    clickAction={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                    tooltip={t('profile.cvInfo')}
                    icon={<Info fontSize="small" />}
                    size="xsmall"
                  />
                </Grid>
                <Grid>
                  <Popover
                    open={infoOpen}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Box m={2}>
                      <Typography>{`${t('profile.cvDescription')}:`}</Typography>
                      <Typography>{chooseDBTranslation(i18n, selectedCV).description}</Typography>
                    </Box>
                  </Popover>
                </Grid>
                {copyRule() && (
                  <Grid>
                    <EditButton
                      clickAction={() => {
                        setEdit(selectedCV)
                        setCopy(true)
                      }}
                      tooltip={t('profile.editTitle')}
                      size="xsmall"
                    />
                  </Grid>
                )}
              </Grid>
            }
          />
        )}
        {CVOptions.length > 1 &&
          selectedCV &&
          copyRule() &&
          selectedCV.id !== personData?.defaultCvId &&
          !selectedCV.original &&
          !mobileView && (
            <Tab
              component="div"
              sx={{
                maxHeight: '44px',
                margin: 0,
                padding: 0,
                minWidth: 0,
              }}
              disableRipple
              label={
                <Grid container>
                  <Grid>
                    <CaleoIconButton
                      clickAction={() => setConfirmation(true)}
                      tooltip={t('profile.cvDelete')}
                      icon={<DeleteIcon fontSize="small" />}
                      size="xsmall"
                    />
                  </Grid>
                </Grid>
              }
            />
          )}
        {!mobileView && copyRule() && (
          <Tab
            component="div"
            sx={{
              maxHeight: '44px',
              margin: 0,
              padding: 0,
              minWidth: 0,
            }}
            disableRipple
            label={
              <CaleoIconButton
                clickAction={() => setProfileWizard(true)}
                tooltip={t('profile.fillingWizard.title')}
                icon={<AssistantIcon fontSize="small" />}
                size="xsmall"
              />
            }
          />
        )}
        {!cvLoading && typeof profileProgress !== 'undefined' && !mobileView && (
          <Tab
            component="div"
            sx={{
              maxHeight: '44px',
              margin: 0,
              padding: 0,
              minWidth: 0,
            }}
            disableRipple
            label={
              <ProgressCircle value={profileProgress} action={setProgressModal} tooltip={t('profile.progressTitle')} />
            }
          />
        )}
        {!mobileView && (
          <Tab
            component="div"
            sx={{
              maxHeight: '44px',
              margin: 0,
              padding: 0,
              minWidth: 0,
            }}
            disableRipple
            label={
              <Grid
                sx={{
                  display: 'block',
                  backgroundColor: theme.palette.text.primary,
                  width: '1px',
                  marginLeft: '15px',
                  marginRight: '15px',
                  height: '43px',
                }}
              ></Grid>
            }
          />
        )}
        {!mobileView &&
          (copyRule() || networkManagerAccess) &&
          features &&
          features.includes('parse') &&
          parseCredits > 0 && (
            <Tab
              component="div"
              sx={{
                maxHeight: '44px',
                margin: 0,
                padding: 0,
                minWidth: 0,
              }}
              disableRipple
              label={
                <CaleoIconButton
                  clickAction={() => {
                    setCvParse(true)
                  }}
                  tooltip={t('profile.parseCV')}
                  icon={<DocumentScannerIcon fontSize="small" />}
                />
              }
            />
          )}
        {selectedCV && features && groups && features.includes('salespool') && groups.includes('sales') && (
          <Tab
            component="div"
            sx={{
              maxHeight: '44px',
              margin: 0,
              padding: 0,
              minWidth: 0,
            }}
            disableRipple
            label={
              <CaleoIconButton
                clickAction={addToPool}
                icon={<GroupAddIcon />}
                size="xsmall"
                tooltip={t('profile.addToPool')}
              />
            }
          />
        )}
        {selectedCV && !mobileView && (
          <Tab
            component="div"
            sx={{
              maxHeight: '44px',
              margin: 0,
              padding: 0,
              minWidth: 0,
            }}
            disableRipple
            label={
              <CaleoIconButton
                clickAction={() => {
                  navigate(`/print/${selectedCV.id}`, {
                    state: {
                      profile: true,
                      search: location.state?.search,
                      personId: location.state?.personId,
                    },
                  })
                }}
                icon={<PictureAsPdf />}
                size="xsmall"
                tooltip={t('profile.print')}
              />
            }
          />
        )}
        {confirmation && (
          <DeleteConfirm
            open={confirmation}
            confirm={() => deleteItem(selectedCV.id)}
            onClose={() => setConfirmation(false)}
          />
        )}
      </Tabs>
    </ToolbarItems>
  )
}

export default ProfileToolbarItems

import { Box, DialogContent, Grid } from '@mui/material'
import { cvAPI } from 'api/CvAPI'
import { personAPI } from 'api/PersonAPI'
import { CountriesData } from 'api/UtilsAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import DataContext from 'Components/reusable/DataContext'
import { ImageDropzone } from 'Components/reusable/InputFields/Dropzone/ImageDropzone'
import newWithEditModal from 'Components/reusable/HOC/newWithEditModal'
import DeleteButton from 'Components/reusable/IconButtons/DeleteButton'
import { useNotificationPopup } from 'Components/reusable/Notification'
import { useUser } from 'hooks'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/src/ReactCrop.scss'
import { IError } from 'types/error'
import { IPerson } from 'types/userInterfaces'
import { getCroppedImg } from 'utils/utils'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported */
interface IProfileModalProps {
  /** Country options. */
  countries: CountriesData
  /** Person ID. */
  personId: number
  /** Old profile image. */
  oldImage: string
  /** Function for changing profile image. */
  changeOldImage: (image: string) => void
  /** CV data. */
  data
  /** Indicator for submitting updated profile. */
  submitIndicator: boolean
  /** Function for resetting submit indicator. */
  resetSubmit: () => void
  /** Function for closing the modal. */
  onClose: () => void
  /** Is phone required. */
  isPhoneRequired?: boolean
}

/**
 * Profile basic details modal.
 *
 * @returns Component for editing profile basic details.
 * @notExported
 */
const ProfileModal: React.FC<IProfileModalProps> = ({
  countries,
  personId,
  oldImage,
  changeOldImage,
  data,
  submitIndicator,
  resetSubmit,
  onClose,
  isPhoneRequired = false,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const { t } = useTranslation()
  const { setRefresh } = useUser()
  const { setSuccessNotificationPopup } = useNotificationPopup()

  const [image, setImage] = useState<HTMLImageElement>()
  const [crop, setCrop] = useState<{
    aspect?: number
    x: number
    y: number
    width: number
    unit: 'px' | '%'
  }>({ aspect: 1 / 1, unit: '%', width: 50, x: 25, y: 25 })
  const [croppedImage, setCroppedImage] = useState<File>()
  const [backendError, setBackendError] = useState<IError>()

  interface CountryType {
    code: string
    label: string
  }

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (submitIndicator === true) {
        try {
          await cvAPI.setCvAbout(data.CV.id, data, controller)
          if (isComponentMounted.current) {
            if (croppedImage) {
              const formData = new FormData()
              formData.append('file', croppedImage)
              formData.append('personId', data.id)
              await personAPI.setProfileImage(formData, controller)
            }
            onClose()
            setSuccessNotificationPopup()
            if (croppedImage) setRefresh(true)
          }
        } catch (error) {
          setBackendError(error as IError)
          resetSubmit()
        }
      }
    })()

    return () => {
      controller.abort()
    }
  }, [submitIndicator])

  const countriesList: CountryType[] = []
  for (const property in countries) {
    countriesList.push({ code: property, label: countries[property] })
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        const newimage = new Image()
        newimage.src = reader.result
        setImage(newimage)
      }
    }
    reader.readAsDataURL(acceptedFiles[0])
  }, [])

  const onCropComplete = async (_: unknown, crop: Crop) => {
    if (image && crop) {
      const croppedImageFile = await getCroppedImg(crop, image)
      if (croppedImageFile instanceof File && isComponentMounted.current) {
        setCroppedImage(croppedImageFile)
      }
    }
  }

  const deleteProfileImage = async () => {
    try {
      await personAPI.deleteProfileImage(personId)
      if (isComponentMounted.current) changeOldImage('')
    } catch (error) {
      setBackendError(error as IError)
    }
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item md={6} sm={6} xs={12}>
          <Grid sx={{ display: 'block', width: '100%', height: '100%', marginBottom: '25px' }}>
            {!image && (
              <ImageDropzone
                onDrop={onDrop}
                accept={{ 'image/jpg': ['.jpg'], 'image/png': ['.png'] }}
                multiple={false}
                oldImage={!image ? oldImage : ''}
                dragHint={t('dropbox.dragProfilePicture')}
                dropHint={t('dropbox.dropProfilePicture')}
              />
            )}
            {image && (
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontFamily: 'Helvetica',
                  width: '100%',
                  height: '218px',
                  borderRadius: '5px',
                  backgroundColor: theme => theme.palette.neutral.light,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Grid
                  sx={{
                    borderRadius: '5px 5px 0 0',
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ReactCrop
                    keepSelection
                    src={image.src}
                    crop={crop}
                    onChange={newCrop => {
                      setCrop(newCrop)
                    }}
                    ruleOfThirds
                    onComplete={onCropComplete}
                    imageStyle={{ height: '188px' }}
                  />
                </Grid>
                <Grid
                  sx={{
                    display: 'flex',
                    width: '100%',
                    height: '30px',
                    alignSelf: 'flex-end',
                    backgroundColor: theme => theme.palette.warning.main,
                    borderRadius: '0 0 5px 5px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box sx={{ fontSize: '14px', fontWeight: 'bold', color: theme => theme.palette.background.default }}>
                    {t('dropbox.cropInfo')}
                  </Box>
                </Grid>
              </Grid>
            )}
            {oldImage && <DeleteButton clickAction={deleteProfileImage} size="medium" />}
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <DataContext.TextField<IPerson> field="firstName" required fullWidth insetLabel />
          <DataContext.TextField<IPerson> field="lastName" required fullWidth insetLabel />
          <DataContext.PhoneInput<IPerson>
            field="telephone"
            fullWidth
            insetLabel
            required={isPhoneRequired}
            type="tel"
          />
          <DataContext.TextField<IPerson> field="streetAddress" fullWidth insetLabel />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DataContext.TextField<IPerson> field="postalCode" fullWidth insetLabel />
            </Grid>
            <Grid item xs={6}>
              <DataContext.TextField<IPerson> field="city" fullWidth insetLabel />
            </Grid>
          </Grid>
          <DataContext.CountryField<IPerson> field="country" countriesOptions={countriesList} fullWidth insetLabel />
        </Grid>
      </Grid>
    </DialogContent>
  )
}

export default newWithEditModal(ProfileModal)

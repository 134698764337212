import RemoveIcon from '@mui/icons-material/Remove'
import DeleteButton from 'Components/reusable/IconButtons/DeleteButton'
import EditButton from 'Components/reusable/IconButtons/EditButton'
import { useUser } from 'hooks/user'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IAllocation } from 'types/allocationInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { convertToDisplayDate } from 'utils/utils'
import { IAllocationManagementTableData } from 'types/allocationInterfaces'
import CustomTable from 'Components/reusable/Tables/CustomTable'
import WarningIcon from '@mui/icons-material/Warning'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import CaleoIconButton from 'Components/reusable/IconButtons/CaleoIconButton'
import AssignmentIcon from '@mui/icons-material/Assignment'
import UserIcon from '@mui/icons-material/Portrait'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'hooks/data'
import { ColumnDef, PaginationState, VisibilityState } from '@tanstack/react-table'
import { sortByDateColumn } from 'Components/reusable/Tables/CustomTable/sortFunctions'
import { Grid, Tooltip, useMediaQuery, useTheme } from '@mui/material'

/** @notExported */
interface IPeopleAllocationTableProps {
  /** People allocation data. */
  items: IAllocationManagementTableData[]
  /** Function to open item.  */
  openItem: (item: IAllocation) => void
  /** Function to delete item.   */
  deleteItem: (item: IAllocation) => void
  /** Person ID. */
  personId: number
  /** Sales access permissions. */
  salesAccess: boolean
}

/**
 * Allocation table component for people.
 *
 * @returns Allocation table component for people.
 * @notExported
 */
const PeopleAllocationTable: React.FC<IPeopleAllocationTableProps> = ({
  items,
  openItem,
  deleteItem,
  personId,
  salesAccess,
}) => {
  const { t, i18n } = useTranslation()
  const { groups } = useUser()
  const navigate = useNavigate()
  const [allocationManagementCount, setAllocationManagementCount] = useLocalStorage('allocationManagementCount', 10)
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
  const [globalFilter, setGlobalFilter] = React.useState<string>('')

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: allocationManagementCount ? allocationManagementCount : 25,
  })

  const managementColumns = React.useMemo<ColumnDef<IAllocationManagementTableData>[]>(() => {
    const cols = [
      {
        id: 'user',
        header: t('allocation.user'),
        accessorFn: row => {
          return `${row.firstName} ${row.lastName}`
        },
      },
      {
        id: 'project',
        header: t('allocation.project'),
        accessorFn: row => {
          if (row.Allocation) {
            if (row.Allocation.type === 'project') {
              return row.Allocation.Assignment
                ? chooseDBTranslation(i18n, row.Allocation.Assignment).name
                : t('allocation.project')
            } else if (row.Allocation.type === 'personal') {
              return t('allocation.ownProject')
            } else {
              return t('allocation.other')
            }
          }
        },
        cell: ({ row }) => {
          if (row.original.Allocation) {
            if (row.original.Allocation.type === 'project') {
              return row.original.Allocation.Assignment
                ? chooseDBTranslation(i18n, row.original.Allocation.Assignment).name
                : t('allocation.project')
            } else if (row.original.Allocation.type === 'personal') {
              return t('allocation.ownProject')
            } else {
              return t('allocation.other')
            }
          }
          return <RemoveIcon />
        },
      },
      {
        id: 'roleId',
        header: t('allocation.roleId'),
        accessorFn: row => (row.Allocation ? chooseDBTranslation(i18n, row.Allocation.AssignmentRole).name : ''),
        cell: ({ row }) => {
          if (row.original.Allocation?.AssignmentRole) {
            return chooseDBTranslation(i18n, row.original.Allocation.AssignmentRole).name
          }
          return <RemoveIcon />
        },
      },
      {
        id: 'percent',
        header: t('allocation.percent'),
        accessorFn: row => (row.Allocation ? `${row.Allocation.percent}%` : ''),
        cell: ({ row }) => {
          if (row.original.Allocation) {
            return `${row.original.Allocation.percent}%`
          }
          return <RemoveIcon />
        },
      },
      {
        id: 'startDate',
        header: t('allocation.startDate'),
        accessorFn: row => (row.Allocation ? convertToDisplayDate(row.Allocation.startDate) : ''),
        sortingFn: (rowA, rowB, columnId) => sortByDateColumn(rowA, rowB, columnId),
        cell: ({ row }) => {
          if (row.original.Allocation?.startDate) return convertToDisplayDate(row.original.Allocation.startDate)
          return <RemoveIcon />
        },
      },
      {
        id: 'endDate',
        header: t('allocation.endDate'),
        accessorFn: row =>
          row.Allocation && row.Allocation.endDate ? convertToDisplayDate(row.Allocation.endDate) : '',
        sortingFn: (rowA, rowB, columnId) => sortByDateColumn(rowA, rowB, columnId),
        cell: ({ row }) => {
          const endDate = row.original.Allocation?.endDate
          if (endDate) {
            const dateThreeMonthsAfter = new Date()
            dateThreeMonthsAfter.setMonth(dateThreeMonthsAfter.getMonth() + 3)
            const daysUntilExpry = Math.ceil(endDate.getTime() - dateThreeMonthsAfter.getTime()) / (1000 * 60 * 60 * 24)

            return (
              <Grid container alignItems="center" spacing={1}>
                {endDate <= new Date() && (
                  <Grid item>
                    <Tooltip title={t('allocation.endedTooltip')}>
                      <PriorityHighIcon color="error" />
                    </Tooltip>
                  </Grid>
                )}
                {daysUntilExpry < 0 && endDate > new Date() && (
                  <Grid item>
                    <Tooltip title={t('allocation.closeToEndTooltip')}>
                      <WarningIcon color="warning" />
                    </Tooltip>
                  </Grid>
                )}
                <Grid item>{convertToDisplayDate(endDate)}</Grid>
              </Grid>
            )
          }
          return <RemoveIcon />
        },
      },
      {
        id: 'controls',
        enableHiding: false,
        cell: ({ row }) => {
          if (row.original.Allocation) {
            const Allocation = row.original.Allocation

            let editable = false

            if (
              (salesAccess || groups.includes('admin')) &&
              (Allocation.type === 'project' || Allocation.type === 'other') &&
              !Allocation.assignmentRoleProposalId
            ) {
              editable = true
            }

            if (Allocation.type === 'personal' && row.original.id === personId) {
              editable = true
            }

            if (editable) {
              return (
                <Grid container alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <CaleoIconButton
                      icon={<AssignmentIcon fontSize="small" />}
                      tooltip={t('allocation.toAssignment')}
                      clickAction={() => navigate(`/assignments/${Allocation?.assignmentId}`)}
                    />
                  </Grid>
                  <Grid item>
                    <CaleoIconButton
                      icon={<UserIcon />}
                      tooltip={t('dashboard.toProfile')}
                      clickAction={() => navigate(`/profile/${row.original?.id}`)}
                    />
                  </Grid>
                  <Grid item>
                    <EditButton clickAction={() => openItem(Allocation)} />
                  </Grid>
                </Grid>
              )
            } else {
              if (Allocation.assignmentRoleProposalId && (salesAccess || groups.includes('admin'))) {
                return (
                  <DeleteButton
                    clickAction={() => deleteItem(Allocation)}
                    tooltip={t('allocation.proposal.remove', {
                      assignmentName: chooseDBTranslation(i18n, Allocation.Assignment)?.name,
                    })}
                  />
                )
              }
            }
          }
          return null
        },
      },
    ]

    if (mobileView) {
      return cols.filter(col => col.id === 'user' || col.id === 'controls')
    }

    return cols
  }, [items, i18n.language, mobileView])

  return (
    <Grid sx={{ width: '100%' }}>
      <CustomTable
        columns={managementColumns}
        data={items}
        search
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        rowCount={allocationManagementCount}
        setRowCount={setAllocationManagementCount}
        initialPageSize={10}
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        setPagination={setPagination}
        pageIndex={pageIndex}
        pageSize={pageSize}
        elevation={0}
        newStyle
      />
    </Grid>
  )
}

export default PeopleAllocationTable

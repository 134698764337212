import React, { useMemo } from 'react'
import { addDays, isFirstDayOfMonth } from 'date-fns'
import colors from 'constants/colors'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'

/** @notExported */
interface TimelineProps {
  /** start date. */
  startDate: Date
  /** end date. */
  endDate: Date
  /** day size. */
  daySize: number
  /** minimum width. */
  minWidth: number
}

/**
 * Timeline component.
 *
 * @returns Timeline element.
 * @notExported
 */
const Timeline: React.FC<TimelineProps> = ({ startDate, endDate, daySize, minWidth }) => {
  const { i18n } = useTranslation()
  const locale = i18n.language === 'fi' ? 'fi-FI' : 'en-US'

  const start = startDate

  const end = endDate

  const blocks = useMemo(() => {
    const newBlocks: { month: string; days: number }[] = []
    let day = new Date(start)
    while (day <= end) {
      if (isFirstDayOfMonth(day)) {
        newBlocks.push({
          month: day.toLocaleDateString(locale, { month: 'short' }),
          days: 1,
        })
      } else {
        if (newBlocks.length) {
          newBlocks[newBlocks.length - 1].days = newBlocks[newBlocks.length - 1].days + 1
        } else {
          newBlocks.push({
            month: day.toLocaleDateString(locale, { month: 'short' }),
            days: 1,
          })
        }
      }

      day = addDays(day, 1)
    }

    return newBlocks
  }, [start, end])

  if (!blocks) {
    return null
  }

  return (
    <Grid container wrap="nowrap" minWidth={minWidth}>
      {blocks.map((block, i) => (
        <Grid
          key={i}
          item
          style={{
            width: daySize * block.days,
            float: 'left',
            height: 30,
            borderTop: `1px ${colors.gray} solid`,
            borderLeft: `1px ${colors.gray} solid`,
            overflow: 'hidden',
          }}
        >
          <Grid style={{ fontSize: 15, textAlign: 'center', fontWeight: 'bold' }}>{block.month}</Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default Timeline

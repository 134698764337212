import React from 'react'
import { useTranslation } from 'react-i18next'
import defaultImg from '../PDFrender/default.jpg'
import { chooseDBTranslation } from 'utils/translations'
import { Box, Grid, Paper, useTheme } from '@mui/material'
import About from './About'
import Skill from './Skill'
import DataLayout from './DataLayout'
import Language from './Language'
import { usePreviewData } from '../PrintPreviewProvider'

/**
 * Renders a quick preview of user data including profile information, skills, employments, projects, degrees, courses, certificates, references, languages, and URLs.
 *
 * @param {Array} countries - an array of country names
 * @param {Array} languages - an array of language names
 * @param {string} profileImage - the URL of the user's profile image
 * @param {string} orgLogo - the URL of the organization's logo
 * @param {Object} orgColors - an object containing color values for the organization
 * @return {JSX.Element} the rendered quick preview component
 * @notExported
 */
const QuickPreview = ({ countries, languages, profileImage, orgLogo, orgColors }) => {
  const { visibleLayout, visibleData } = usePreviewData()
  const { i18n } = useTranslation()
  const theme = useTheme()
  const locale = i18n.language
  const options = { year: 'numeric', month: 'short', day: 'numeric' } as const

  if (!visibleData || !visibleLayout) return <></>

  return (
    <Grid
      container
      sx={{ height: '0', paddingBottom: '125%', position: 'relative' }}
      direction="row"
      justifyContent="center"
    >
      <Grid
        item
        sx={{
          position: 'absolute',
          width: '100%',
          maxWidth: '21cm',
          height: '100%',
          overflowY: 'scroll',
          alignItems: 'center',
        }}
      >
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'start',
            background: theme.palette.background.default,
            borderRadius: '0px',
            position: 'relative',
          }}
        >
          <Grid
            container
            direction="row"
            sx={{
              paddingTop: '30pt',
              paddingBottom: '30pt',
              paddingLeft: '10pt',
              paddingRight: '10pt',
              fontSize: 10,
              color: theme.palette.neutral.dark,
            }}
          >
            <Grid item xs={12}>
              <Grid
                container
                sx={
                  visibleLayout.id !== 0 &&
                  visibleLayout.hideProfileImage === true &&
                  visibleLayout.showContactInfo === false
                    ? {
                        width: '100%',
                        height: '110pt',
                        borderRadius: 5,
                        display: 'flex',
                        flexDirection: 'row',
                      }
                    : { borderTopLeftRadius: 100, borderBottomLeftRadius: 100, width: '100%', height: 230 }
                }
                justifyContent="space-between"
                direction="row"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  sx={
                    visibleLayout.id !== 0 && visibleLayout.hideProfileImage === true
                      ? {
                          width: '100%',
                          height: '54pt',
                          borderRadius: 5,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          backgroundColor: orgColors.color,
                        }
                      : {
                          width: '483pt',
                          height: '54pt',
                          marginLeft: '92pt',
                          borderTopRightRadius: 5,
                          borderBottomRightRadius: 5,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          backgroundColor: orgColors.color,
                        }
                  }
                >
                  <Box
                    sx={{
                      color: theme.palette.background.paper,
                      fontSize: '8.5pt',
                      marginTop: '10pt',
                      marginRight: '10pt',
                      fontWeight: 'bold',
                    }}
                  >
                    {new Date().toLocaleDateString(locale, options)}
                  </Box>
                </Grid>
                {visibleLayout.hideProfileImage === false && (
                  <img
                    style={{ height: '184pt', width: '184pt', borderRadius: 130, position: 'absolute' }}
                    src={profileImage ? profileImage : defaultImg}
                  />
                )}
                <Grid
                  item
                  xs={visibleLayout.hideProfileImage === false ? 4 : 7}
                  sx={
                    visibleLayout.id !== 0 && visibleLayout.hideProfileImage === true
                      ? { marginLeft: '20pt', width: '230pt', display: 'flex' }
                      : { marginLeft: '219pt', width: '230pt', display: 'flex' }
                  }
                >
                  <Grid container direction="row">
                    <Grid
                      item
                      xs={12}
                      sx={{
                        fontFamily: 'Raleway',
                        fontSize: '16.8pt',
                        marginTop: '15px',
                        lineHeight: 1.5,
                        fontWeight: 900,
                        color: orgColors.accentColor,
                      }}
                    >
                      {visibleLayout && visibleLayout.hideName
                        ? chooseDBTranslation(i18n, visibleData.about).primaryRole
                        : `${visibleData.about.firstName ?? ''} ${visibleData.about.lastName ?? ''}`}
                    </Grid>
                    {visibleLayout && visibleLayout.hideName === false && (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          fontSize: '11.8pt',
                          fontFamily: 'Raleway',
                          fontWeight: 'bold',
                          lineHeight: 1.7,
                          marginRight: '5px',
                          color: theme.palette.neutral.dark,
                        }}
                      >
                        {chooseDBTranslation(i18n, visibleData.about).primaryRole}
                      </Grid>
                    )}
                    {visibleLayout.showContactInfo && (
                      <Grid item xs={12} style={{ marginTop: '5pt' }}>
                        <Grid sx={{ fontSize: '11.8pt', color: theme.palette.neutral.dark, lineHeight: 1.7 }}>
                          {visibleData.about.telephone ?? ''}
                        </Grid>
                        <Grid sx={{ fontSize: '11.8pt', color: theme.palette.neutral.dark, lineHeight: 1.7 }}>
                          {visibleData.about.email ?? ''}
                        </Grid>
                        <Grid style={{ marginTop: '5pt' }}></Grid>
                        <Grid sx={{ fontSize: '11.8pt', color: theme.palette.neutral.main }}>
                          {visibleData.about.streetAddress ?? ''}
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12} sx={{ fontSize: '11.8pt', color: theme.palette.neutral.main }}>
                      {visibleLayout.showContactInfo && `${visibleData.about.postalCode ?? ''} `}
                      {visibleData.about.city && visibleData.about.country
                        ? `${visibleData.about.city}, ${countries[visibleData.about.country?.toUpperCase()]}`
                        : ''}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <img
                    style={{ width: '100pt', height: '100pt', marginTop: '10pt', marginRight: '20pt' }}
                    src={orgLogo ? orgLogo : defaultImg}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 40 }}>
              {chooseDBTranslation(i18n, visibleData.about).about &&
              chooseDBTranslation(i18n, visibleData.about).about.length > 0 ? (
                <About
                  description={chooseDBTranslation(i18n, visibleData.about).about}
                  layout={visibleLayout}
                  headingColor={orgColors.accentColor}
                />
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {visibleData.skills.length > 0 && (
                <Skill
                  items={visibleData.skills}
                  headingColor={orgColors.accentColor}
                  type="skills"
                  isHidden={false}
                  hideLastUsed={visibleLayout.hideLastUsed}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {visibleData.industries.length > 0 && (
                <Skill
                  items={visibleData.industries}
                  headingColor={orgColors.accentColor}
                  type="industries"
                  isHidden={visibleLayout.id === 0 ? true : false}
                  hideLastUsed={visibleLayout.hideLastUsed}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {visibleData.roles.length > 0 && (
                <Skill
                  items={visibleData.roles}
                  headingColor={orgColors.accentColor}
                  type="roles"
                  isHidden={visibleLayout.id === 0 ? true : false}
                  hideLastUsed={visibleLayout.hideLastUsed}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {visibleData.employments.length > 0 && (
                <DataLayout data={visibleData} type={'employments'} headingColor={orgColors.accentColor} />
              )}
            </Grid>
            <Grid item xs={12}>
              {visibleData.projects.length > 0 && (
                <DataLayout data={visibleData} type={'projects'} headingColor={orgColors.accentColor} />
              )}
            </Grid>
            <Grid item xs={12}>
              {visibleData.degrees.length > 0 && (
                <DataLayout data={visibleData} type={'degrees'} headingColor={orgColors.accentColor} />
              )}
            </Grid>
            <Grid item xs={12}>
              {visibleData.courses.length > 0 && (
                <DataLayout data={visibleData} type={'courses'} headingColor={orgColors.accentColor} />
              )}
            </Grid>
            <Grid item xs={12}>
              {visibleData.certificates.length > 0 && (
                <DataLayout
                  data={visibleData}
                  type={'certificates'}
                  headingColor={orgColors.accentColor}
                  layout={visibleLayout}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {visibleData.references.length > 0 && (
                <DataLayout data={visibleData} type={'references'} headingColor={orgColors.accentColor} />
              )}
            </Grid>
            <Grid item xs={12}>
              {visibleData.languages.length > 0 && (
                <Language items={visibleData.languages} headingColor={orgColors.accentColor} languages={languages} />
              )}
            </Grid>
            <Grid item xs={12}>
              {visibleData.urls.length > 0 && (
                <DataLayout data={visibleData} type={'urls'} headingColor={orgColors.accentColor} />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default QuickPreview

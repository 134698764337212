import { Grid, Divider, Box, useTheme } from '@mui/material'
import colors from 'constants/colors'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { IPersonSkillOrIndustryOrRole } from 'types/cvsInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { roundToDecimal, skillLevels } from 'utils/utils'

/** @notExported */
interface ISkillProps {
  /** Skill items  */
  items
  /** Heading color */
  headingColor
  /** Skill type */
  type
  /** Is skill hidden */
  isHidden
  /** Hide last used */
  hideLastUsed: boolean
}

/**
 * Skill component
 *
 * @returns Skill component
 * @notExported
 */
const Skill: React.FC<ISkillProps> = ({ items, headingColor, type, isHidden, hideLastUsed }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()

  const rows: ReactNode[] = items.map((item: IPersonSkillOrIndustryOrRole) => (
    <Grid
      container
      direction="row"
      alignItems="start"
      justifyContent="space-between"
      key={item.id}
      sx={{ width: '100%', lineHeight: 1 }}
    >
      <Grid item xs={6} sx={{ fontSize: '10.1pt', maxWidth: '120pt', flexWrap: 'wrap', lineHeight: 1 }}>
        {chooseDBTranslation(i18n, item.Skill).name}
      </Grid>
      <Grid container item xs={6} style={{ marginBottom: '5px' }} sx={{ width: 100, justifyContent: 'center' }}>
        <Grid item xs={12}>
          <Box style={{ fontSize: '10pt', color: colors.primary }}>{`${item.level}. ${
            item.level ? t(skillLevels()[(item.level ?? 1) - 1].label) : ''
          }`}</Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ fontSize: '10pt', color: colors.primary }}>
            {`${t('profile.skill.experience')}: ${
              item.experienceMonths
                ? `${roundToDecimal(item.experienceMonths / 12, 1)} ${t('profile.skill.years.abbr')}`
                : '-'
            }`}
          </Box>
        </Grid>
        {!hideLastUsed && (
          <Grid item xs={12}>
            <Box style={{ fontSize: '10pt', color: colors.primary }}>
              {t('profile.skill.lastUsed')}: {item.lastUsed ? item.lastUsed : ''}
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  ))

  if (isHidden) {
    return <></>
  }

  return (
    <>
      <Grid sx={{ paddingTop: '10pt', paddingLeft: '20pt', paddingRight: '20pt', fontSize: 10 }}>
        <Grid
          sx={{
            fontSize: '18.8pt',
            fontFamily: 'Raleway',
            fontWeight: 800,
            marginBottom: '8pt',
            letterSpacing: 0,
            borderBottom: `2pt solid ${theme.palette.neutral.dark}`,
            marginTop: '18pt',
            minWidth: '100%',
            color: headingColor ? headingColor : colors.secondary,
          }}
        >
          {t(`print.${type}.title`)}
        </Grid>
        <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
          <Grid sx={{ width: '49%' }}>
            {rows.map((row: ReactNode, i: number) => {
              if (i + 1 <= (rows.length + 1) / 2) {
                return (
                  <Grid key={type + i} sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '100%' }}>
                    {row}
                  </Grid>
                )
              }
            })}
          </Grid>
          <Grid sx={{ width: '49%' }}>
            {rows.map((row: ReactNode, i: number) => {
              if (i + 1 > (rows.length + 1) / 2) {
                return (
                  <Grid key={type + i} sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '100%' }}>
                    {row}
                  </Grid>
                )
              }
            })}
          </Grid>
        </Grid>
        <Grid container alignItems="flex-end" justifyContent="space-between" style={{ marginTop: '10pt' }}>
          <Box sx={{ marginHorizontal: '10pt', fontSize: '10.1pt', color: theme.palette.neutral.main }}>{`1 - ${t(
            'print.skills.fundamentals'
          )}`}</Box>
          <Divider orientation="vertical" variant="fullWidth" flexItem sx={{ color: colors.gray }} />
          <Box sx={{ marginHorizontal: '10pt', fontSize: '10.1pt', color: theme.palette.neutral.main }}>{`2 - ${t(
            'print.skills.novice'
          )}`}</Box>
          <Divider orientation="vertical" variant="fullWidth" flexItem sx={{ color: colors.gray }} />
          <Box sx={{ marginHorizontal: '10pt', fontSize: '10.1pt', color: theme.palette.neutral.main }}>{`3 - ${t(
            'print.skills.intermediate'
          )}`}</Box>
          <Divider orientation="vertical" variant="fullWidth" flexItem sx={{ color: colors.gray }} />
          <Box sx={{ marginHorizontal: '10pt', fontSize: '10.1pt', color: theme.palette.neutral.main }}>{`4 - ${t(
            'print.skills.advanced'
          )}`}</Box>
          <Divider orientation="vertical" variant="fullWidth" flexItem sx={{ color: colors.gray }} />
          <Box sx={{ marginHorizontal: '10pt', fontSize: '10.1pt', color: theme.palette.neutral.main }}>{`5 - ${t(
            'print.skills.expert'
          )}`}</Box>
        </Grid>
      </Grid>
    </>
  )
}

export default React.memo(Skill)

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IReference } from 'types/cvsInterfaces'
import { IItemVisibility } from 'types/layoutInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import VisibilityCheckbox from '../VisibilityCheckbox'
import colors from 'constants/colors'

/**
 * Renders the References component.
 *
 * @param {Array} references - An array of references.
 * @param {Object} layout - The layout object.
 * @param {Function} hideSection - The function to hide the section.
 * @param {Function} hideItem - The function to hide an item.
 * @param {boolean} expanded - A boolean indicating whether the accordion is expanded.
 * @param {Function} setStatus - The function to set the status.
 * @return {JSX.Element} The rendered References component.
 * @notExported
 */
const References = ({ references, layout, hideSection, hideItem, expanded, setStatus }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()

  let hidden = false
  if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
    const visibilities = layout.ItemVisibilities.filter(
      (visibility: IItemVisibility) => visibility.type === 'references'
    )
    if (visibilities.length > 0) {
      const visibilityMap = new Map(visibilities.map(item => [item.itemId, item.hidden]))
      hidden = references.every(value => visibilityMap.get(value.id) === true)
    }
  }

  const Item = ({ reference }) => {
    let hiddenItem = false
    if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
      const visibilities = layout.ItemVisibilities.filter(
        (visibility: IItemVisibility) => visibility.type === 'references'
      )
      if (visibilities.length > 0) {
        hiddenItem = visibilities.find(
          (value: IItemVisibility) => value.itemId === reference.id && value.hidden === true
        )
      }
    }

    return (
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        width="100%"
        my={2}
        sx={{
          padding: '2px',
          backgroundColor: theme.palette.background.default,
          '&:hover': {
            backgroundColor: theme.palette.background.paper,
            borderRadius: '5px',
          },
        }}
      >
        <Box width="100%">
          <Box display="flex" flexDirection="row" flexWrap="nowrap" width="100%" mb={1}>
            <VisibilityCheckbox
              checked={!!hiddenItem}
              onChange={(checked: boolean) => hideItem(checked, reference.id, 'references')}
            />
            <Box mr={1} fontWeight="fontWeightBold">
              {t('print.reference.name')}:
            </Box>
            <Box display="inline">{`${reference.firstName} ${reference.lastName}`}</Box>
          </Box>

          <Box display="flex" flexDirection="row" flexWrap="nowrap" width="100%" mb={1}>
            <Box mr={1} fontWeight="fontWeightBold">
              {t('print.reference.company')}:
            </Box>
            <Box display="inline">{reference.company}</Box>
          </Box>
          <Box>{chooseDBTranslation(i18n, reference).letter}</Box>
        </Box>
      </Box>
    )
  }

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={(e, expanded) => setStatus(expanded)}
      elevation={0}
      sx={{ borderRadius: '5px', border: `1px solid ${colors.borderColor}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          fontSize: '20px',
          color: theme.palette.primary.main,
          fontWeight: 'bold',
        }}
      >
        <Grid container alignItems="center">
          <Grid item>
            <VisibilityCheckbox checked={hidden} onChange={(checked: boolean) => hideSection(checked, 'references')} />
          </Grid>
          <Grid item>{t('print.layout.references')}</Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" flexWrap="wrap" width="100%">
          {references.map((reference: IReference, index: number) => {
            return (
              <Box key={`reference-${index}`}>
                <Item reference={reference} />
                {index + 1 < references.length ? <Divider variant="fullWidth" /> : null}
              </Box>
            )
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default React.memo(References)

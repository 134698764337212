import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined'
import { Checkbox, Grid } from '@mui/material'
import React from 'react'
import { ISkillInterestLevel } from 'types/cvsInterfaces'
import { skillInterestLevels } from 'utils/utils'

/**
 * Interest level picker component.
 *
 * @param value - The current value.
 * @param onChange - The change handler.
 * @param disabled - Whether the component is disabled.
 * @param smallIcons - Whether to use small icons.
 * @returns The interest level picker component.
 * @notExported
 */
const InterestLevelPicker = <T extends ISkillInterestLevel>({
  value,
  onChange,
  disabled = false,
  smallIcons = false,
}: {
  value: T | null
  onChange: (newValue: T | null) => void
  disabled?: boolean
  smallIcons?: boolean
}) => {
  const levels = skillInterestLevels()

  const handleChange = (
    e: React.ChangeEvent<{
      value: unknown
    }>
  ) => {
    if (e.target.value == value) {
      onChange(null)
    } else {
      onChange(Number(e.target.value) as T)
    }
  }

  return (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        {levels.map((tempValue, index) => (
          <Checkbox
            key={'levelBulb' + index}
            disabled={disabled}
            onChange={handleChange}
            checked={(value ?? 0) > index}
            value={tempValue.level}
            icon={<ThumbUpAltOutlinedIcon fontSize={smallIcons ? 'small' : 'large'} color="disabled" />}
            checkedIcon={<ThumbUpIcon color="success" fontSize={smallIcons ? 'small' : 'large'} />}
            name={'levelBulb' + index}
            size={smallIcons ? 'small' : 'medium'}
            sx={smallIcons ? { padding: 1 } : {}}
            color="success"
          />
        ))}
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid
          sx={{
            color: theme => theme.palette.neutral.main,
            fontSize: '12px',
            fontFamily: 'Helvetica',
          }}
        >
          {levels.find(levelValue => levelValue.level === value)?.label ?? null}
        </Grid>
      </Grid>
    </>
  )
}

export default InterestLevelPicker

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { IBlock } from 'types/allocationInterfaces'
import colors from 'constants/colors'
import { lightenDarkenColor, roundToDecimal } from 'utils/utils'
import { addMonths, differenceInDays } from 'date-fns'

/** Styles for Allocation page. */
export const useStyles = makeStyles((theme: Theme) => ({
  allocationsContainer: {
    maxWidth: 1200,
    backgroundColor: colors.searchBackground,
    margin: '0 auto',
    backgroundImage: 'none',
  },
  allocationsHeader: {
    fontWeight: 'bold',
  },
  allocationTable: {
    width: '100%',
  },
  yearOption: {
    fontSize: '18px',
    fontWeight: 'bold',
    opacity: '25%',
  },
  yearOptionSelected: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  legendOwn: {
    width: '15px',
    height: '15px',
    borderRadius: '30%',
    display: 'inline-block',
    backgroundColor: theme.palette.secondary.main,
  },
  legendEmpty: {
    width: '15px',
    height: '15px',
    borderRadius: '30%',
    color: theme.palette.neutral.main,
    display: 'inline-block',
    backgroundColor: theme.palette.background.default,
  },
  legendZero: {
    width: '15px',
    height: '15px',
    borderRadius: '30%',
    display: 'inline-block',
    opacity: 0.5,
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  legendNotFull: {
    width: '15px',
    height: '15px',
    borderRadius: '30%',
    display: 'inline-block',
    backgroundColor: theme.palette.warning.main,
  },
  legendFull: {
    width: '15px',
    height: '15px',
    borderRadius: '30%',
    display: 'inline-block',
    backgroundColor: theme.palette.error.main,
  },
  legendOverbooked: {
    width: '15px',
    height: '15px',
    borderRadius: '30%',
    opacity: 0.8,
    display: 'inline-block',
    backgroundColor: theme.palette.info.main,
  },
  legendText: {
    marginRight: '25px',
    marginLeft: '5px',
  },
  calendarOwn: {
    borderBottom: '4px solid rgba(32, 173, 208, 1) !important',
    borderRadius: '2px 2px 0 0',
  },
  calendarFull: {
    borderBottom: '4px solid rgba(190, 60, 60, 1) !important',
    borderRadius: '2px 2px 0 0',
  },
  calendarNotFull: {
    borderBottom: '4px solid rgba(230, 162, 25, 1) !important',
    borderRadius: '2px 2px 0 0',
  },
  tileCustomContent: {
    zIndex: 10,
    position: 'absolute',
    width: '100%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  monthText: {
    color: theme.palette.primary.main,
    opacity: '1',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  monthTextPast: {
    color: theme.palette.primary.main,
    opacity: '0.8',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  allocationTimeline: {
    marginRight: '30px',
    maxWidth: '680px',
  },
  profileImage: {
    width: 22,
    height: 22,
    borderRadius: '30%',
    marginRight: 3,
  },
  weekBorder: {
    borderLeft: `1px solid ${theme.palette.primary.main}`,
  },
  weekEndBorder: {
    borderRight: `1px solid ${theme.palette.primary.main}`,
  },
  fontStyle: {
    fontSize: '22px',
  },
  personDetails: {
    fontWeight: 'bold',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

/**
 * Generates the style object for a block based on its properties.
 *
 * @param {IBlock} block - The block object containing the properties of the block.
 * @param {number} timeScope - The time scope in months used for calculating the multiplier.
 * @return {Object} The style object with the width, border, color, and borderRadius properties.
 */
export const blockStyle = (block: IBlock, timeScope: number) => {
  let color: string = colors.background
  let textColor: string = colors.description

  if (block.percent === 0) {
    color = colors.gray
  } else if (block.percent < 100) {
    color = colors.orange
  } else if (block.percent < 101) {
    color = colors.red
    textColor = colors.background
  } else if (block.percent > 100) {
    color = colors.purple
    textColor = colors.background
  }

  const multiplier = 100 / differenceInDays(addMonths(new Date(), timeScope), new Date())

  const style: {
    width: string
    border: string
    color: string
    borderRadius: number
    fontSize: string
    background?: string
    backgroundColor?: string
  } = {
    width: `${roundToDecimal(block.length * multiplier, 5)}%`,
    border: `1px solid ${color}`,
    color: textColor,
    borderRadius: 3,
    fontSize: '1em',
  }

  if (block.verified) {
    style.backgroundColor = lightenDarkenColor(color, 50)
  } else {
    if (block.percent > 0) {
      style.background = `repeating-linear-gradient(
      45deg,
      ${lightenDarkenColor(color, 50)},
      ${lightenDarkenColor(color, 50)} 10px,
      ${lightenDarkenColor(color, 20)} 10px,
      ${lightenDarkenColor(color, 20)} 20px
    )`
    }
  }

  return style
}

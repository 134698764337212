import { DialogContent, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import newWithConfirmationModal from 'Components/reusable/HOC/newWithConfirmationModal'

/** @notExported */
interface DeleteConfirmProps {
  /** Confirmation text. */
  confirmationText?: string
  /** Header text. */
  headerText?: string
}

/**
 * Delete confim component.
 *
 * @returns Delete confirm component.
 * @notExported
 */
const DeleteConfirm: React.FC<DeleteConfirmProps> = ({ confirmationText, headerText }) => {
  const { t } = useTranslation()

  return (
    <DialogContent>
      <Typography color="primary" style={{ fontWeight: 'bold' }} variant="h2">
        {headerText ? headerText : t('confirmation.text')}
      </Typography>
      {confirmationText && <p>{confirmationText}</p>}
    </DialogContent>
  )
}

export default newWithConfirmationModal(DeleteConfirm)

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IEducation } from 'types/cvsInterfaces'
import { IItemVisibility } from 'types/layoutInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { convertToDisplayDate } from 'utils/utils'
import VisibilityCheckbox from '../VisibilityCheckbox'
import colors from 'constants/colors'

/**
 * Renders the Education component.
 *
 * @param {Object} educations - The educations object.
 * @param {Object} layout - The layout object.
 * @param {Function} hideSection - The function to hide a section.
 * @param {Function} hideItem - The function to hide an item.
 * @param {boolean} expanded - The expanded state of the accordion.
 * @param {Function} setStatus - The function to set the status.
 * @return {JSX.Element} The rendered Education component.
 * @notExported
 */
const Education = ({ educations, layout, hideSection, hideItem, expanded, setStatus }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()

  const degrees = educations.degrees
  const courses = educations.courses
  const certificates = educations.certificates

  let hidden = false
  let degreeHidden = false
  let courseHidden = false
  let certHidden = false

  if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
    const visibilities = layout.ItemVisibilities.filter((visibility: IItemVisibility) => visibility.type === 'degrees')
    if (visibilities.length > 0 && visibilities.length >= degrees.length) {
      const visibilityMap = new Map(visibilities.map(item => [item.itemId, item.hidden]))
      degreeHidden = degrees.every(value => visibilityMap.get(value.id) === true)
    }
  }

  if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
    const visibilities = layout.ItemVisibilities.filter((visibility: IItemVisibility) => visibility.type === 'courses')
    if (visibilities.length > 0 && visibilities.length >= courses.length) {
      const visibilityMap = new Map(visibilities.map(item => [item.itemId, item.hidden]))
      courseHidden = courses.every(value => visibilityMap.get(value.id) === true)
    }
  }

  if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
    const visibilities = layout.ItemVisibilities.filter(
      (visibility: IItemVisibility) => visibility.type === 'certificates'
    )
    if (visibilities.length > 0 && visibilities.length >= certificates.length) {
      const visibilityMap = new Map(visibilities.map(item => [item.itemId, item.hidden]))
      certHidden = certificates.every(value => visibilityMap.get(value.id) === true)
    }
  }

  if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
    if (degreeHidden && courseHidden && certHidden) {
      hidden = true
    }
  }

  const Item = ({ item }) => {
    const itemTransFields = Object.keys(item.translations[0])
    const type = itemTransFields.includes('school')
      ? 'degrees'
      : itemTransFields.includes('organizer')
      ? 'courses'
      : itemTransFields.includes('certifier')
      ? 'certificates'
      : null

    // create date string
    let date = ''
    if (type === 'degrees' || type === 'courses') {
      // degree and course
      if (item.startDate) {
        date += `${convertToDisplayDate(item.startDate, item.dateResolution)} - `
      }
      if (item.endDate) {
        date += `${convertToDisplayDate(item.endDate, item.dateResolution)}`
      }
    } else if (type === 'certificates') {
      // certificate
      if (item.issueDate) {
        date += `${convertToDisplayDate(item.issueDate, item.dateResolution)} - `
      }
      if (item.endDate) {
        date += `${convertToDisplayDate(item.endDate, item.dateResolution)}`
      }
    }

    let hidden = false
    if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
      const visibilities = layout.ItemVisibilities.filter((visibility: IItemVisibility) => visibility.type === type)
      if (visibilities.length > 0) {
        hidden = visibilities.find((value: IItemVisibility) => value.itemId === item.id && value.hidden === true)
      }
    }

    return (
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        width="100%"
        my={2}
        sx={{
          padding: '2px',
          backgroundColor: theme.palette.background.default,
          '&:hover': {
            backgroundColor: theme.palette.background.paper,
            borderRadius: '5px',
          },
        }}
      >
        <Box width="100%">
          <Box display="flex" flexDirection="row" flexWrap="nowrap" width="100%" mb={1}>
            <VisibilityCheckbox checked={!!hidden} onChange={(checked: boolean) => hideItem(checked, item.id, type)} />
            <Box fontWeight="fontWeightBold" flexGrow={1}>
              {type === 'degrees'
                ? chooseDBTranslation(i18n, item).degree
                : type === 'courses'
                ? chooseDBTranslation(i18n, item).course
                : type === 'certificates'
                ? chooseDBTranslation(i18n, item).certificate
                : null}
            </Box>
            <Box justifyContent="flex-start">{date}</Box>
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="nowrap" width="100%" mb={1}>
            <Box display="inline">
              {type === 'degrees'
                ? chooseDBTranslation(i18n, item).school
                : type === 'courses'
                ? chooseDBTranslation(i18n, item).organization
                : type === 'certificates'
                ? chooseDBTranslation(i18n, item).certifier
                : null}
            </Box>
          </Box>
          <Box>
            {type === 'degrees'
              ? chooseDBTranslation(i18n, item).description
              : type === 'courses'
              ? chooseDBTranslation(i18n, item).description
              : type === 'certificates'
              ? item.url
              : null}
          </Box>
          {type === 'certificates' && (
            <>
              <Box>
                {t('print.education.licence')}
                {': ' + item.licenceNumber}
              </Box>
              <Box>
                {t('print.certificateExpiry')}
                {': ' + `${convertToDisplayDate(item.endDate, item.dateResolution)}`}
              </Box>
            </>
          )}
        </Box>
      </Box>
    )
  }

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={(e, expanded) => setStatus(expanded)}
      elevation={0}
      sx={{ borderRadius: '5px', border: `1px solid ${colors.borderColor}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          fontSize: '20px',
          color: theme.palette.primary.main,
          fontWeight: 'bold',
        }}
      >
        <Grid container alignItems="center">
          <Grid item>
            <VisibilityCheckbox
              checked={hidden}
              onChange={(checked: boolean) => {
                hideSection(checked, 'education')
              }}
            />
          </Grid>
          <Grid item>{t('print.layout.education')}</Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            <VisibilityCheckbox
              checked={degreeHidden}
              onChange={(checked: boolean) => hideSection(checked, 'degrees')}
            />
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            >
              {t('print.layout.degrees')}
            </Typography>
          </Box>
          <Divider />
          {degrees.map((degree: IEducation, index: number) => {
            return (
              <Box key={`degree-${index}`}>
                <Item item={degree} />
                {index + 1 < degrees.length ? <Divider variant="fullWidth" /> : null}
              </Box>
            )
          })}
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            <VisibilityCheckbox
              checked={courseHidden}
              onChange={(checked: boolean) => hideSection(checked, 'courses')}
            />
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            >
              {t('print.layout.courses')}
            </Typography>
          </Box>
          <Divider />
          {courses.map((course: IEducation, index: number) => {
            return (
              <Box key={`course-${index}`} display="flex" flexDirection="row" flexWrap="wrap" width="100%">
                <Item item={course} />
                {index + 1 < courses.length ? <Divider variant="fullWidth" /> : null}
              </Box>
            )
          })}
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            <VisibilityCheckbox
              checked={certHidden}
              onChange={(checked: boolean) => hideSection(checked, 'certificates')}
            />
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            >
              {t('print.layout.certificates')}
            </Typography>
          </Box>
          <Divider />
          {certificates.map((certificate: IEducation, index: number) => {
            return (
              <Box key={`certificate-${index}`} display="flex" flexDirection="row" flexWrap="wrap" width="100%">
                <Item item={certificate} />
                {index + 1 < certificates.length ? <Divider variant="fullWidth" /> : null}
              </Box>
            )
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default React.memo(Education)

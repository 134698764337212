import { getAccountVerificationInitialData } from 'Components/reusable/DataContext/InitialData'
import { accountVerificationSchema } from 'Components/reusable/DataContext/ValidationSchema'
import { authAPI } from 'api/AuthAPI'
import { useNotificationPopup } from 'Components/reusable/Notification'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { IActivationUserData } from 'types/userInterfaces'
import { Grid } from '@mui/material'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import DataContext, { NewOrExisting } from 'Components/reusable/DataContext'
import LoadingIndicator from 'Components/reusable/LoadingIndicator'
import { useLogin, useUser } from 'hooks'
import React, { useEffect, useState } from 'react'
import { IVerifyAccount } from 'types/accountInterfaces'
import { IError } from 'types/error'
import CaleoPrimaryButton from 'Components/reusable/Buttons/CaleoPrimaryButton'
import LoginPageCard from 'Components/reusable/CaleoCustomComponents/LoginPageCard'
import LoginPageCardWrapper from 'Components/reusable/CaleoCustomComponents/LoginPageCardWrapper'
import { useTranslation } from 'react-i18next'
import { useIsComponentMounted } from 'hooks/util'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'

type T = IVerifyAccount

/**
 * Renders the Account Verification component.
 *
 * @return {React.FC} The rendered Account Verification component.
 * @notExported
 */
const VerifyAccount: React.FC = () => {
  const isComponentMounted = useIsComponentMounted()
  const navigate = useNavigate()
  const location = useLocation()
  const { token } = useParams()
  const { setNotificationPopup } = useNotificationPopup()
  const { t } = useTranslation()

  const [userData, setUserData] = useState<IActivationUserData>({})
  const [data, setData] = useState<NewOrExisting<T>>(getAccountVerificationInitialData())
  const schema = accountVerificationSchema()
  const localeBase = 'password'
  const [submitLocally, setSubmitLocally] = useState<boolean>(false)
  const [backendError, setBackendError] = useState<IError>()
  const [passwordError, setPasswordError] = useState<string>()
  const { loggingIn, loggedIn, loginLoading } = useLogin()
  const { groupsReady, groups } = useUser()

  const buttonsEnabled = !loggingIn

  useEffect(() => {
    const controller = new AbortController()

    if (!token) {
      navigate({ pathname: '/login' })
      return setNotificationPopup({
        message: t('password.activateAccount.invalidToken'),
        type: 'error',
        duration: 'long',
      })
    }

    ;(async () => {
      try {
        const userData = await authAPI.getUserDataByToken(token, controller)
        if (!isComponentMounted.current) return
        if (location.state && location.state.error) {
          setPasswordError(location.state.error)
          setSubmitLocally(false)
        }
        setUserData(userData)
      } catch (error) {
        const err = error as IError
        if (err.status === 404) {
          navigate({ pathname: '/login' })
          setNotificationPopup({
            message: t('password.activateAccount.invalidToken'),
            type: 'error',
            duration: 'long',
          })
        } else {
          setBackendError(err)
        }
      }
    })()

    return () => {
      controller.abort()
    }
  }, [token])

  useEffect(() => {
    if (passwordError) {
      setPasswordError(undefined)
    }
  }, [data.password.length])

  useEffect(() => {
    if (loggedIn && groupsReady) {
      if (location.state?.url) {
        return navigate(location.state?.url)
      } else if (groups && groups.includes('sales')) {
        return navigate('/employees')
      } else {
        return navigate('/profile')
      }
    }
  }, [loggedIn, groupsReady])

  if (!data) {
    return <LoadingIndicator />
  }

  if (submitLocally) {
    navigate('/activate', {
      state: {
        token: token,
        userData: userData,
        password: data.password,
        previous: location.pathname,
        search: location.search,
      },
    })
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} disableContinue={true} />
  }

  return (
    <LoginPageCardWrapper>
      {loginLoading ? (
        <LoadingIndicator />
      ) : (
        <DataContext
          data={data}
          onChange={setData}
          onSubmit={() => setSubmitLocally(true)}
          schema={schema}
          localeBase={localeBase}
        >
          <LoginPageCard header={t('password.activateAccount')}>
            <Grid container>
              <Grid item xs={12} mt={2}>
                <DataContext.PasswordField<IVerifyAccount>
                  field="password"
                  required
                  fullWidth
                  showPassowordOrHide={true}
                />
              </Grid>
              <Grid item xs={12} mt={2} mb={passwordError ? 0 : 2}>
                <DataContext.PasswordField<IVerifyAccount>
                  field="repeatPassword"
                  required
                  fullWidth
                  showPassowordOrHide={true}
                />
              </Grid>
              {passwordError && (
                <Grid item xs={12} mb={2}>
                  <CardContentText type="error">{t(passwordError)}</CardContentText>
                </Grid>
              )}
            </Grid>
            <Grid container justifyContent="center" spacing={1} alignItems="center">
              <Grid item xs={12} container alignItems="center" spacing={1}>
                <Grid item xs="auto">
                  <DataContext.Validation>
                    {({ valid }) => (
                      <CaleoPrimaryButton
                        valid={valid || !buttonsEnabled}
                        clickAction={() => setSubmitLocally(true)}
                        label={t(`submit`)}
                        loading={false}
                      />
                    )}
                  </DataContext.Validation>
                </Grid>
              </Grid>
            </Grid>
          </LoginPageCard>
        </DataContext>
      )}
    </LoginPageCardWrapper>
  )
}

export default VerifyAccount

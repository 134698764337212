import { Avatar, Button, Chip, Grid, SvgIcon, Typography } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Flag from 'react-world-flags'
import { ICv } from 'types/cvsInterfaces'
import { ISearchFields, ISearchResult } from 'types/searchInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { Link } from 'react-router-dom'
import { personAPI } from 'api/PersonAPI'
import { useIsComponentMounted } from 'hooks/util'
import { IError } from 'types/error'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { CountriesData } from 'api/UtilsAPI'
import { useTranslation } from 'react-i18next'
import { allocationAPI } from 'api/AllocationAPI'
import { IAllocation } from 'types/allocationInterfaces'
import AllocationTimeline from 'Components/reusable/AllocationTimeline/AllocationTimeline'
import { useOverflow } from 'use-overflow'
import { ISalesPoolItem } from 'types/salesPoolInterfaces'
import { useSalesPool } from 'Components/General/SalesPoolProvider/SalesPoolProvider'
import { useNotificationPopup } from 'Components/reusable/Notification'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import { filesAPI } from 'api/FilesAPI'
import colors from 'constants/colors'
import { useUser } from 'hooks'

/** @notExported */
interface IResultContentProps {
  /** The search result. */
  result: ISearchResult
  /** The cv. */
  cv: ICv | null | undefined
  /** The countries options. */
  countries: CountriesData
  /** The search terms. */
  terms?: ISearchFields
  /** The function to set the open proposal. */
  setOpenProposal?: (openProposal: string) => void
  /** Whether the action should be disabled. */
  disableAction?: boolean
  /** Whether to show allocations. */
  showAllocations?: boolean
  /** Whether used in a modal. */
  modal?: boolean
}

/**
 * Component for rendering the result content.
 *
 * @param result - The search result.
 * @param cv - The cv.
 * @param countries - The countries options.
 * @param terms - The search terms.
 * @param setOpenProposal - The function to set the open proposal.
 * @param disableAction - Whether the action should be disabled.
 * @param showAllocations - Whether to show allocations.
 * @returns
 * @notExported
 */
const ResultContent: React.FC<IResultContentProps> = ({
  result,
  cv,
  countries,
  terms,
  setOpenProposal,
  disableAction,
  showAllocations = true,
  modal = false,
}) => {
  const { t, i18n } = useTranslation()
  const isComponentMounted = useIsComponentMounted()
  const ref = useRef<HTMLDivElement>(null)
  const { refXOverflowing } = useOverflow(ref)
  const { editedSalesPool, setEditedSalesPool } = useSalesPool()
  const { setNotificationPopup } = useNotificationPopup()
  const { groups } = useUser()

  const [image, setImage] = useState<string>('')
  const [allocations, setAllocations] = useState<IAllocation[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [backendError, setBackendError] = useState<IError>()

  useEffect(() => {
    const controller = new AbortController()

    if (result.Person.id && !result.publicId) {
      ;(async () => {
        try {
          setLoading(true)
          let allocationData: IAllocation[] | undefined = undefined
          if (showAllocations) {
            allocationData = await allocationAPI.getAllocations(result.Person.id, controller)
          }
          const data = await personAPI.getProfileImage(result.Person.id, controller)
          if (isComponentMounted.current) {
            setImage(data)
            if (showAllocations && allocationData) {
              setAllocations(allocationData)
            }
          }
        } catch (error) {
          setBackendError(error as IError)
        } finally {
          setLoading(false)
        }
      })()
    }

    if (result.publicId) {
      ;(async () => {
        try {
          setLoading(true)
          if (result.publicId) {
            const data = await filesAPI.downloadProfilePublicId(result.publicId, controller)
            let allocationData: IAllocation[] | undefined = undefined
            if (showAllocations) {
              allocationData = await allocationAPI.getAllocationsForPublicId(result.publicId, controller)
            }
            if (isComponentMounted.current) {
              setImage(data)
              if (showAllocations && allocationData) {
                setAllocations(allocationData)
              }
            }
          }
        } catch (error) {
          setBackendError(error as IError)
        } finally {
          setLoading(false)
        }
      })()
    }

    return () => {
      controller.abort()
      setLoading(false)
    }
  }, [result.Person])

  const allocationTimeline = useMemo(() => {
    if (!showAllocations) {
      return null
    }
    if (!allocations || allocations.length < 1) {
      return <AllocationTimeline personId={result.publicId ? result.publicId : result.personId} />
    }
    return <AllocationTimeline personId={result.publicId ? result.publicId : result.personId} data={allocations} />
  }, [allocations, result, loading])

  const primaryRole = useMemo(() => chooseDBTranslation(i18n, cv)?.primaryRole, [i18n.language])
  const organizationName = useMemo(
    () => chooseDBTranslation(i18n, result.Person.Site?.Organization)?.name,
    [i18n.language]
  )
  const city = result.Person.Site?.city
  const country = result.Person.Site?.countryCode.toUpperCase()

  const requiredSkillIds = terms ? terms.skills.map(skill => skill.skillId) : []

  let sortedArray
  if (requiredSkillIds.length && cv && cv.PersonSkills) {
    sortedArray = [
      ...cv?.PersonSkills.filter(({ skillId }) => requiredSkillIds.includes(skillId)),
      ...cv?.PersonSkills.filter(({ skillId }) => !requiredSkillIds.includes(skillId)),
    ]
  } else {
    sortedArray = cv?.PersonSkills ?? []
  }

  const skillChips = useMemo(
    () =>
      sortedArray.map(personSkill => {
        const name = chooseDBTranslation(i18n, personSkill.Skill).name
        return (
          <Chip
            key={personSkill.id}
            size="small"
            label={name}
            color={requiredSkillIds.includes(personSkill.skillId) ? 'primary' : undefined}
            variant={requiredSkillIds.includes(personSkill.skillId) ? 'filled' : 'outlined'}
            sx={{ mr: 0.5, borderRadius: 0.5 }}
          />
        )
      }),
    [i18n.language]
  )

  const certificateChips = useMemo(
    () =>
      cv?.Certificates.map(certificate => {
        const name = chooseDBTranslation(i18n, certificate)?.certificate
        return (
          <Chip key={certificate.id} variant="outlined" size="small" label={name} sx={{ mr: 0.5, borderRadius: 0.5 }} />
        )
      }),
    [i18n.language]
  )

  const LinkBehavior = props => (
    <Link
      to={`/profile/${result.personId}`}
      state={{
        search: cv ? cv.id : null,
        personId: cv ? cv.personId : null,
      }}
      {...props}
      role={undefined}
    />
  )

  /**
   * Generates a list of skills.
   *
   * @param {boolean} isAnonymous - indicates if the user is anonymous
   * @return {JSX.Element} the list of skills as a JSX element
   */
  const generateSkillList = (isAnonymous?: boolean): JSX.Element => (
    <Grid container wrap="nowrap" justifyContent="space-between" my={0.5}>
      <Grid
        ref={ref}
        item
        xs={8}
        md={9}
        lg={10}
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          WebkitMaskImage: refXOverflowing ? 'linear-gradient(90deg, #000 70%, transparent)' : undefined,
        }}
      >
        {skillChips}
      </Grid>
      <Grid item>
        {!disableAction && (
          <>
            {isAnonymous && result.anonymousId ? (
              <Button
                variant="contained"
                color="newPrimary"
                size="small"
                onClick={() => {
                  if (result.anonymousId && !!setOpenProposal) setOpenProposal(result.anonymousId)
                }}
              >
                {t('search.proposeAssignment')}
              </Button>
            ) : (
              <Button variant="contained" color="newPrimary" size="small" component={LinkBehavior}>
                {t('search.fullProfile')}
              </Button>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )

  /**
   * Renders a list of certificates.
   *
   * @return {JSX.Element} The rendered certificate list component
   */
  const generateCertificateList = () => (
    <Grid item xs={12} container alignItems="center">
      <SvgIcon sx={{ p: 0.5, color: colors.newPrimary }}>
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <path d="M458.622 255.92l45.985-45.005c13.708-12.977 7.316-36.039-10.664-40.339l-62.65-15.99 17.661-62.015c4.991-17.838-11.829-34.663-29.661-29.671l-61.994 17.667-15.984-62.671C337.085.197 313.765-6.276 300.99 7.228L256 53.57 211.011 7.229c-12.63-13.351-36.047-7.234-40.325 10.668l-15.984 62.671-61.995-17.667C74.87 57.907 58.056 74.738 63.046 92.572l17.661 62.015-62.65 15.99C.069 174.878-6.31 197.944 7.392 210.915l45.985 45.005-45.985 45.004c-13.708 12.977-7.316 36.039 10.664 40.339l62.65 15.99-17.661 62.015c-4.991 17.838 11.829 34.663 29.661 29.671l61.994-17.667 15.984 62.671c4.439 18.575 27.696 24.018 40.325 10.668L256 458.61l44.989 46.001c12.5 13.488 35.987 7.486 40.325-10.668l15.984-62.671 61.994 17.667c17.836 4.994 34.651-11.837 29.661-29.671l-17.661-62.015 62.65-15.99c17.987-4.302 24.366-27.367 10.664-40.339l-45.984-45.004z"></path>
        </svg>
      </SvgIcon>
      {certificateChips}
    </Grid>
  )

  /**
   * Generates a list of language flags based on the language skills in the CV.
   *
   * @return {JSX.Element} The list of language flags as JSX elements.
   */
  const generateLanguageList = () => {
    if (cv && cv.LanguageSkills) {
      return cv.LanguageSkills.map(languageSkill => {
        return (
          <Flag
            key={languageSkill.id}
            style={{
              height: '30px',
              width: '32px',
              margin: 5,
            }}
            code={
              languageSkill.languageCode === 'en'
                ? 'gb'
                : languageSkill.languageCode === 'sv'
                ? 'se'
                : languageSkill.languageCode
            }
            fallback={<span></span>}
          />
        )
      })
    }

    return null
  }

  const addToPool = () => {
    if ('id' in result.Person) {
      const newItem = {
        personId: result.Person.id,
        Person: result.Person,
      } as ISalesPoolItem

      if (
        editedSalesPool &&
        editedSalesPool.SalesPoolItems &&
        editedSalesPool.SalesPoolItems.length > 0 &&
        editedSalesPool.SalesPoolItems.find(item => item.personId === newItem.personId)
      ) {
        setNotificationPopup({
          message: t('salesPool.itemExists'),
          type: 'warning',
          duration: 'short',
        })
      } else {
        if (editedSalesPool && editedSalesPool.SalesPoolItems) {
          setEditedSalesPool({
            ...editedSalesPool,
            SalesPoolItems: [...editedSalesPool.SalesPoolItems, newItem],
          })
        } else {
          setEditedSalesPool({
            title: '',
            anonymous: false,
            durationInWeeks: 4,
            allocationsVisible: false,
            publicId: undefined,
            SalesPoolItems: [newItem],
          })
        }

        setNotificationPopup({
          message: t('salesPool.itemAdded'),
          type: 'success',
          duration: 'short',
        })
      }
    }
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  if (result.anonymous) {
    return (
      <Grid container spacing={1}>
        <Avatar
          sx={{
            height: 70,
            width: 70,
            mt: 1,
            ml: 1,
          }}
        />
        <Grid item xs={8} lg={9}>
          <Grid item xs={12}>
            {`${primaryRole?.length ? primaryRole : t('resources.searchResults.missingRole')}`}
          </Grid>
          {country && country?.length > 1 && (
            <Grid item xs={12}>
              {`${country ? countries[country] : ''}`}
            </Grid>
          )}
        </Grid>
        <Grid item xs={1} md={1} justifySelf="flex-end" sx={{ textAlign: 'right' }}>
          {generateLanguageList()}
        </Grid>
        {generateSkillList(true)}
        {certificateChips && certificateChips?.length > 0 && generateCertificateList()}
      </Grid>
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={modal ? 2 : 2} md={modal ? 3 : 1.5} lg={modal ? 2 : 1}>
        <Avatar
          sx={{
            height: 70,
            width: 70,
            marginRight: 5,
          }}
          src={image}
        />
      </Grid>
      <Grid item xs={12} sm={modal ? 8 : 7} md={modal ? 7 : 8} lg={modal ? 7 : 8} container>
        <Grid item xs={12}>
          <Typography fontWeight="bold">{`${result.Person.firstName} ${result.Person.lastName}`}</Typography>
        </Grid>
        {((primaryRole && primaryRole?.length > 1) || (organizationName && organizationName?.length > 1)) && (
          <Grid item xs={12}>
            {`${primaryRole?.length ? primaryRole : t('resources.searchResults.missingRole')}${
              organizationName?.length ? `, ${organizationName}` : ''
            }`}
          </Grid>
        )}
        {((city && city?.length > 1) || (country && country?.length > 1)) && (
          <Grid item xs={12}>
            {`${city ? `${city}, ` : ''}${country ? countries[country] : ''}`}
          </Grid>
        )}
      </Grid>
      <Grid
        item
        xs
        justifySelf="flex-end"
        container
        alignItems="center"
        justifyContent="flex-end"
        sx={{ textAlign: 'right' }}
        spacing={3}
      >
        {cv && cv.LanguageSkills && cv.LanguageSkills.length > 0 && <Grid item>{generateLanguageList()}</Grid>}
        {!disableAction && groups.includes('sales') && (
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={e => {
                e.stopPropagation()
                addToPool()
              }}
            >
              <GroupAddIcon />
            </Button>
          </Grid>
        )}
      </Grid>
      {generateSkillList()}
      {certificateChips && certificateChips?.length > 0 && generateCertificateList()}
      {showAllocations && (
        <Grid item xs={12}>
          {allocationTimeline}
        </Grid>
      )}
    </Grid>
  )
}

export default ResultContent

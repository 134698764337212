import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Checkbox, FormControlLabel, Popover, useTheme, useMediaQuery, Button } from '@mui/material'
import { Table } from '@tanstack/react-table'
import CaleoIconButton from 'Components/reusable/IconButtons/CaleoIconButton'
import TableChartIcon from '@mui/icons-material/TableChart'
import CaleoPrimaryButton from 'Components/reusable/Buttons/CaleoPrimaryButton'

/**
 * Table column visibility controls.
 *
 * @param table - Table instance.
 * @returns Column visibility controls.
 * @notExported
 */
const VisibilityControls = ({ table }: { table: Table<any> }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const open = Boolean(anchorEl)

  return (
    <span>
      {mobileView ? (
        <CaleoIconButton
          valid
          icon={<TableChartIcon />}
          tooltip={t('hideColumns')}
          clickAction={(e: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(e.currentTarget)
          }}
        />
      ) : (
        <Button
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(e.currentTarget)
          }}
          size="small"
          variant="contained"
          color="primary"
        >
          {t('hideColumns')}
        </Button>
      )}
      <Popover
        open={open}
        onClose={() => {
          setAnchorEl(null)
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container direction="column" px={1}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={table.getIsAllColumnsVisible()}
                  onChange={() => {
                    table.toggleAllColumnsVisible()
                  }}
                  color="secondary"
                />
              }
              label={t('toggleAll')}
            />
          </Grid>
          {table.getAllLeafColumns().map(column => {
            if (column.id !== 'controls' && typeof column.columnDef.header === 'string') {
              return (
                <Grid item key={column.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={column.id}
                        checked={column.getIsVisible()}
                        onChange={() => {
                          column.toggleVisibility()
                        }}
                        color="secondary"
                      />
                    }
                    label={<>{column.columnDef.header ?? ''}</>}
                  />
                </Grid>
              )
            }
          })}
        </Grid>
      </Popover>
    </span>
  )
}

export default VisibilityControls

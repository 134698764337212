import { Snackbar, Grid } from '@mui/material'
import { Alert } from '@mui/material'
import i18n from 'i18n'
import React, { useCallback, useContext, useState } from 'react'

const notificationPopupDurations = {
  short: 2000,
  long: 5000,
  indefinite: undefined,
}

export type NotificationPopupDuration = keyof typeof notificationPopupDurations

interface INotificationPopup {
  message: string
  type?: 'error' | 'warning' | 'info' | 'success'
  duration?: NotificationPopupDuration
  action?: React.ReactNode
}

interface NotificationPopupApi {
  setNotificationPopup: (options: INotificationPopup) => void
  setSuccessNotificationPopup: () => void
}

const notificationPopupContext = React.createContext<NotificationPopupApi>({
  setNotificationPopup: () => {
    /**/
  },
  setSuccessNotificationPopup: () => {
    /**/
  },
})

const { Provider } = notificationPopupContext

/**
 * Notification provider.
 *
 * @param children
 * @returns Provider for handling notification operations.
 */
export const NotificationPopupProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<INotificationPopup>()

  const setNotificationPopup = useCallback(
    (options: INotificationPopup) => {
      setOpen(true)
      setOptions(options)
    },
    [options]
  )
  const setSuccessNotificationPopup = () => {
    setOpen(true)
    setOptions({
      message: i18n.t('changesSaved'),
      type: 'success',
      duration: 'short',
    })
  }

  const handleClose = () => setOpen(false)

  return (
    <Provider
      value={{
        setNotificationPopup,
        setSuccessNotificationPopup,
      }}
    >
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={notificationPopupDurations[options?.duration ?? 'indefinite']}
        onClose={handleClose}
      >
        <Alert elevation={6} variant="filled" severity={options?.type} onClose={handleClose}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {options?.message}
            </Grid>
            <Grid item xs={12} justifySelf="flex-end">
              {options?.action}
            </Grid>
          </Grid>
        </Alert>
      </Snackbar>
    </Provider>
  )
}

/**
 * Hook for notification operations.
 *
 * @returns Notification provider context variables and functions.
 */
export const useNotificationPopup = () => {
  return useContext(notificationPopupContext)
}

import { Button, Checkbox, Chip, DialogContent, FormControlLabel, Grid, Typography } from '@mui/material'
import { teamAPI } from 'api/TeamAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import newWithEditModal from 'Components/reusable/HOC/newWithEditModal'
import DataContext from 'Components/reusable/DataContext'
import MemberPicker from 'Components/reusable/InputFields/MemberPicker'
import { useNotificationPopup } from 'Components/reusable/Notification'
import PersonPicker from 'Components/reusable/InputFields/PersonPicker'
import { useUser } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IError } from 'types/error'
import { OrganizationId } from 'types/ids'
import { ITeam } from 'types/teamInterfaces'
import { IOrganization, IPerson } from 'types/userInterfaces'
import { useIsComponentMounted } from 'hooks/util'
import AddIcon from '@mui/icons-material/Add'
import colors from 'constants/colors'

type newMember = {
  accountId: number
  manager: boolean
  name: string
}

/** @notExported */
interface ITeamModalProps {
  /** The team. */
  item: ITeam | null | undefined
  /** The submit indicator. */
  submitIndicator: boolean
  /** A function to reset the submit indicator. */
  resetSubmit: () => void
  /** The data. */
  data: ITeam
  /** A function to close the modal. */
  onClose: ({ newItem }) => void
}

/**
 * TeamModal component.
 *
 * @param item - The team.
 * @param submitIndicator - The submit indicator.
 * @param resetSubmit - A function to reset the submit indicator.
 * @param data - The data.
 * @param onClose - A function to close the modal.
 * @returns The TeamModal component.
 * @notExported
 */
const TeamModal: React.FC<ITeamModalProps> = ({ item, submitIndicator, resetSubmit, data, onClose }) => {
  const isComponentMounted = useIsComponentMounted()
  const { t } = useTranslation()
  const { user } = useUser()
  const { setSuccessNotificationPopup } = useNotificationPopup()

  const [person, setPerson] = useState<IPerson>()
  const [manager, setManager] = useState<boolean>(false)
  const [members, setMembers] = useState<newMember[]>([])
  const [organization, setOrganization] = useState<IOrganization | null>(null)
  const [backendError, setBackendError] = useState<IError>()
  const [teamOrganizations, setTeamOrganizations] = useState<IOrganization[]>([])

  useEffect(() => {
    if (item) {
      const teamMembers: newMember[] = []
      for (const member of item.TeamMembers) {
        teamMembers.push({
          accountId: member.Account.id,
          manager: member.manager,
          name: `${member.Account.Person?.firstName} ${member.Account.Person?.lastName}`,
        })
      }
      setMembers(teamMembers)
      if (item.Organization) {
        setOrganization(item.Organization)
      }
    }
  }, [item, user])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (user?.organizationId) {
        const results = await teamAPI.allowedTeamOrganizations(user.organizationId, controller)
        if (!isComponentMounted.current) return
        setTeamOrganizations(results)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [item, user])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (submitIndicator === true) {
        try {
          let newItem: ITeam
          if (item) {
            newItem = await teamAPI.save(item.id, { ...data, members }, controller)
          } else {
            newItem = await teamAPI.create({ ...data, members }, controller)
          }
          if (!isComponentMounted.current) return
          onClose({ newItem })
          setSuccessNotificationPopup()
        } catch (err) {
          setBackendError(err as IError)
          resetSubmit()
        }
      }
    })()

    return () => {
      controller.abort()
    }
  }, [submitIndicator])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const addMember = () => {
    if (person) {
      const member = {
        accountId: person.accountId,
        manager: manager,
        name: `${person.firstName} ${person.lastName}`,
      }
      setMembers([...members, member])
    }
    setPerson(undefined)
    setManager(false)
  }

  const removeMember = member => {
    setMembers(members.filter(m => m.accountId !== member.accountId))
  }

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item md={6} sm={6} xs={12}>
          <DataContext.TranslationImport<ITeam> />
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            {t('team.info')}
          </Typography>
          <DataContext.TextField<ITeam> field="name" required fullWidth insetLabel />
          <DataContext.TextField<ITeam>
            field="description"
            fullWidth
            maxHintLength={1500}
            multiline
            grow
            rows={10}
            insetLabel
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            {t('team.members')}
          </Typography>
          {teamOrganizations.length ? (
            <MemberPicker
              type="teamOrganization"
              value={organization}
              onChange={newOrg => {
                setOrganization(newOrg as IOrganization)
              }}
              teamOrganizations={teamOrganizations}
              insetLabel
              label={t('team.modal.organization')}
            />
          ) : null}
          <PersonPicker
            value={person ?? null}
            organizationId={
              teamOrganizations && organization ? organization.id : (user?.organizationId as OrganizationId)
            }
            members={members}
            onChange={newValue => {
              if (newValue) {
                setPerson(newValue)
              }
            }}
            insetLabel
            label={t('team.modal.person')}
          />
          <Grid container justifyContent="space-between" mt={1}>
            <FormControlLabel
              control={
                <Checkbox
                  id="manager"
                  checked={manager}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setManager(e.target.checked)}
                  name="manager"
                />
              }
              label={t('team.modal.manager')}
            />
            <Button startIcon={<AddIcon />} variant="contained" size="small" color="primary" onClick={addMember}>
              {t('add')}
            </Button>
          </Grid>
          <Grid>
            {members.map((member, i) =>
              member.manager ? (
                <Chip
                  key={i}
                  sx={{ mr: 1, mb: 1, borderRadius: 1 }}
                  label={
                    <Typography noWrap>
                      {`${member.name} `}
                      <span style={{ fontSize: '0.7em', color: colors.green }}>{t('team.modal.manager')}</span>
                    </Typography>
                  }
                  onDelete={() => removeMember(member)}
                  variant="outlined"
                />
              ) : (
                <Chip
                  key={i}
                  sx={{ mr: 1, mb: 1, borderRadius: 1 }}
                  label={member.name}
                  onDelete={() => removeMember(member)}
                  variant="outlined"
                />
              )
            )}
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
  )
}

export default newWithEditModal(TeamModal)

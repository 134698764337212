import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from 'react-i18next'

/** @notExported */
interface AddButtonProps {
  /** Action to perform when button is clicked. */
  clickAction: () => void
  /** Tooltip for button. */
  tooltip?: string
  /** Whether or not the button is valid. */
  valid?: boolean
  /** Size of the button. */
  size?: 'medium' | 'small' | undefined | 'xsmall'
}

/**
 * Add button component.
 *
 * @returns Add button component.
 * @notExported
 * @category Buttons
 */
const AddButton: React.FC<AddButtonProps> = ({ clickAction, tooltip, valid = true, size }) => {
  const { t } = useTranslation()

  const getButton = () => {
    return (
      <IconButton
        sx={{ float: 'right' }}
        onClick={() => clickAction()}
        disabled={!valid}
        size={size === 'xsmall' ? 'medium' : size}
      >
        <AddIcon fontSize={size === 'xsmall' ? 'small' : 'large'} />
      </IconButton>
    )
  }

  return (
    <Tooltip title={tooltip ?? (t('add') as string)}>
      <>{getButton()}</>
    </Tooltip>
  )
}

export default AddButton

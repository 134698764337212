import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IEmployer, IPersonRole } from 'types/cvsInterfaces'
import { IItemVisibility } from 'types/layoutInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { convertToDisplayDate } from 'utils/utils'
import VisibilityCheckbox from '../VisibilityCheckbox'
import colors from 'constants/colors'

/**
 * Renders the work history section of the component.
 *
 * @param {Object} employments - The list of employments.
 * @param {Object} layout - The layout configuration.
 * @param {boolean} hideSection - Flag indicating whether the section is hidden.
 * @param {boolean} hideItem - Flag indicating whether an item is hidden.
 * @param {boolean} expanded - Flag indicating whether the section is expanded.
 * @param {function} setStatus - Function to set the status of the section.
 * @return {JSX.Element} The rendered work history section.
 * @notExported
 */
const WorkHistory = ({ employments, layout, hideSection, hideItem, expanded, setStatus }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()

  let hidden = false
  if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
    const visibilities = layout.ItemVisibilities.filter(
      (visibility: IItemVisibility) => visibility.type === 'employments'
    )
    if (visibilities.length > 0) {
      const visibilityMap = new Map(visibilities.map(item => [item.itemId, item.hidden]))
      hidden = employments.every(value => visibilityMap.get(value.id) === true)
    }
  }

  const Item = ({ employment }) => {
    let hiddenItem = false
    if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
      const visibilities = layout.ItemVisibilities.filter(
        (visibility: IItemVisibility) => visibility.type === 'employments'
      )
      if (visibilities.length > 0) {
        hiddenItem = visibilities.find(
          (value: IItemVisibility) => value.itemId === employment.id && value.hidden === true
        )
      }
    }

    return (
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        width="100%"
        my={2}
        sx={{
          padding: '2px',
          backgroundColor: theme.palette.background.default,
          '&:hover': {
            backgroundColor: theme.palette.background.paper,
            borderRadius: '5px',
          },
        }}
      >
        <Box width="100%">
          <Box display="flex" flexDirection="row" flexWrap="nowrap" width="100%" mb={1}>
            <VisibilityCheckbox
              checked={!!hiddenItem}
              onChange={(checked: boolean) => hideItem(checked, employment.id, 'employments')}
            />
            <Box fontWeight="fontWeightBold" flexGrow={1}>
              {chooseDBTranslation(i18n, employment).name}
            </Box>
            <Box justifyContent="flex-end">
              {convertToDisplayDate(employment.startDate, employment.dateResolution)} -{' '}
              {employment.endDate ? convertToDisplayDate(employment.endDate, employment.dateResolution) : null}
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="nowrap" width="100%" mb={1}>
            <Box mr={1} fontWeight="fontWeightBold">
              {t('print.employment.roles')}:
            </Box>
            <Box display="inline">
              {chooseDBTranslation(i18n, employment).primaryRole.length > 0
                ? chooseDBTranslation(i18n, employment).primaryRole
                : employment.EmployerRoles.map((role: IPersonRole) => `${chooseDBTranslation(i18n, role.Skill).name} `)}
            </Box>
          </Box>
          <Box>{chooseDBTranslation(i18n, employment).description}</Box>
        </Box>
      </Box>
    )
  }

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={(e, expanded) => setStatus(expanded)}
      elevation={0}
      sx={{ borderRadius: '5px', border: `1px solid ${colors.borderColor}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          fontSize: '20px',
          color: theme.palette.primary.main,
          fontWeight: 'bold',
        }}
      >
        <Grid container alignItems="center">
          <Grid item>
            <VisibilityCheckbox checked={hidden} onChange={(checked: boolean) => hideSection(checked, 'employments')} />
          </Grid>
          <Grid item>{t(`print.layout.workhistory`)}</Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" flexWrap="wrap" width="100%">
          {employments.map((employment: IEmployer, index: number) => {
            return (
              <Box key={`employment-${index}`}>
                <Item employment={employment} />
                {index + 1 < employments.length ? <Divider variant="fullWidth" /> : null}
              </Box>
            )
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default React.memo(WorkHistory)

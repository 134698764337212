import { DialogContent } from '@mui/material'
import { layoutAPI } from 'api/LayoutAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import newWithEditModal from 'Components/reusable/HOC/newWithEditModal'
import DataContext from 'Components/reusable/DataContext'
import { useNotificationPopup } from 'Components/reusable/Notification'
import React, { useEffect, useState } from 'react'
import { IError } from 'types/error'
import { IItemOrder, IItemVisibility, ILayout, INewLayout } from 'types/layoutInterfaces'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported */
interface ILayoutModalProps {
  /** Layout data */
  data: INewLayout
  /** Submit indicator */
  submitIndicator: boolean
  /** Function to reset submit */
  resetSubmit: () => void
  /** CV ID */
  cvId: number
  /** Function to set selected layout */
  setSelectedLayout: (newLayout: ILayout) => void
  /** Function to refresh layout  */
  refresh: (newLayout) => void
  /** Function to close modal */
  onClose: () => void
  /** Item orders */
  orders: IItemOrder[]
  /** Item visibilities */
  visibilities: IItemVisibility[]
}

/**
 * Layout modal component.
 *
 * @returns Layout modal component.
 * @notExported
 */
const LayoutModal: React.FC<ILayoutModalProps> = ({
  data,
  submitIndicator,
  resetSubmit,
  cvId,
  setSelectedLayout,
  refresh,
  onClose,
  orders = [],
  visibilities = [],
}) => {
  const isComponentMounted = useIsComponentMounted()
  const [backendError, setBackendError] = useState<IError>()
  const { setSuccessNotificationPopup } = useNotificationPopup()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (data && submitIndicator) {
        try {
          const newLayout = await layoutAPI.createLayout(cvId, orders, visibilities, data?.name, controller)

          if (!isComponentMounted.current) return
          setSelectedLayout(newLayout)
          refresh(newLayout)
          setSuccessNotificationPopup()
          onClose()
        } catch (err) {
          setBackendError(err as IError)
          resetSubmit()
        }
      }
    })()

    return () => {
      controller.abort()
    }
  }, [submitIndicator])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <DialogContent>
      <DataContext.TextField<INewLayout> field="name" required fullWidth insetLabel />
    </DialogContent>
  )
}

export default newWithEditModal(LayoutModal)

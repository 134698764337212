import { DialogContent, FormControlLabel, Grid, Switch } from '@mui/material'
import { notificationAPI } from 'api/NotificationAPI'
import ModalPaper from 'Components/reusable/CaleoCustomComponents/ModalPaper'
import withSimpleModal from 'Components/reusable/HOC/withSimpleModal'
import { useNotificationPopup } from 'Components/reusable/Notification'
import { useUser } from 'hooks'
import React, { useEffect, useState } from 'react'
import { IError } from 'types/error'
import ErrorOverlay from '../ErrorOverlay'
import { useTranslation } from 'react-i18next'
import { IOptoutSetting } from 'types/notificationInterfaces'
import { useIsComponentMounted } from 'hooks/util'

/** @notExported */
interface INotificationModalProps {
  /** Indicator for submitting data. */
  submitIndicator: boolean
  /** Function for closing modal. */
  onClose: () => void
  /** Function for resetting submit indicator. */
  resetSubmit: () => void
}

/**
 * Notification modal.
 *
 * @returns Modal for managing notification settings.
 * @notExported
 */
const NotificationModal: React.FC<INotificationModalProps> = ({ submitIndicator, onClose, resetSubmit }) => {
  const isComponentMounted = useIsComponentMounted()
  const { t } = useTranslation()
  const { setSuccessNotificationPopup } = useNotificationPopup()
  const { user, setUpdate, features } = useUser()
  const [optoutNewAssignments, setOptoutNewAssignments] = useState<boolean>(true)
  const [networkNotifications, setNetworkNotifications] = useState<boolean>(true)
  /*const [optoutWeeklySummary, setOptoutWeeklySummary] = useState<boolean>(true)
  const [optoutMonthlySummary, setOptoutMonthlySummary] = useState<boolean>(true)*/
  const [optoutChatMessages, setOptoutChatMessages] = useState<boolean>(true)
  const [backendError, setBackendError] = useState<IError>()

  useEffect(() => {
    ;(async () => {
      const controller = new AbortController()

      if (user) {
        try {
          const settings = await notificationAPI.getNotificationSettings(user.id, controller)

          if (isComponentMounted.current) {
            for (const setting of settings) {
              if (setting.type === 'newAssignments') {
                setOptoutNewAssignments(false)
              }
              if (setting.type === 'networkNotifications') {
                setNetworkNotifications(false)
              }
              /*if (setting.type === 'weeklyAssignments') {
                setOptoutWeeklySummary(false)
              }
              if (setting.type === 'monthlyAssignments') {
                setOptoutMonthlySummary(false)
              }*/
              if (setting.type === 'chatMessages') {
                setOptoutChatMessages(false)
              }
            }
          }
        } catch (error) {
          setBackendError(error as IError)
        }
      }

      return () => {
        controller.abort()
      }
    })()
  }, [user])

  useEffect(() => {
    ;(async () => {
      const controller = new AbortController()

      if (submitIndicator && user) {
        try {
          const newOptions: IOptoutSetting[] = []

          if (!optoutNewAssignments) {
            newOptions.push({ accountId: user.id, type: 'newAssignments' })
          }

          if (!networkNotifications) {
            newOptions.push({ accountId: user.id, type: 'networkNotifications' })
          }

          /*if (!optoutWeeklySummary) {
            newOptions.push({ accountId: user.id, type: 'weeklyAssignments' })
          }

          if (!optoutMonthlySummary) {
            newOptions.push({ accountId: user.id, type: 'monthlyAssignments' })
          }*/

          if (!optoutChatMessages) {
            newOptions.push({ accountId: user.id, type: 'chatMessages' })
          }

          await notificationAPI.putNotificationSettings(user.id, newOptions, controller)
          if (isComponentMounted.current) {
            onClose()
            setSuccessNotificationPopup()
            setUpdate(true)
          }
        } catch (error) {
          setBackendError(error as IError)
          resetSubmit()
        }
      }

      return () => {
        controller.abort()
      }
    })()
  }, [submitIndicator])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const changeMainSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setOptoutNewAssignments(true)
      setNetworkNotifications(true)
      /*setOptoutWeeklySummary(true)
      setOptoutMonthlySummary(true)*/
      if (features.includes('chat')) setOptoutChatMessages(true)
    } else {
      setOptoutNewAssignments(false)
      setNetworkNotifications(false)
      /*setOptoutWeeklySummary(false)
      setOptoutMonthlySummary(false)*/
      if (features.includes('chat')) setOptoutChatMessages(false)
    }
  }

  return (
    <DialogContent>
      <ModalPaper>
        <Grid container>
          <FormControlLabel
            control={
              <Switch
                checked={
                  optoutNewAssignments &&
                  optoutChatMessages &&
                  networkNotifications /*&& optoutWeeklySummary && optoutMonthlySummary*/
                }
                onChange={changeMainSwitch}
              />
            }
            label={t('notificationSettings.switch')}
          />
          <Grid item pl={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={optoutNewAssignments}
                  onChange={() => setOptoutNewAssignments(!optoutNewAssignments)}
                />
              }
              label={t('notificationSettings.new-assignments')}
            />
          </Grid>

          <Grid item pl={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={networkNotifications}
                  onChange={() => setNetworkNotifications(!networkNotifications)}
                />
              }
              label={t('notificationSettings.network-notifications')}
            />
          </Grid>

          {/*<Grid item pl={4}>
            <FormControlLabel
              control={
                <Switch checked={optoutWeeklySummary} onChange={() => setOptoutWeeklySummary(!optoutWeeklySummary)} />
              }
              label={t('notificationSettings.weekly-summary')}
            />
          </Grid>
          <Grid item pl={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={optoutMonthlySummary}
                  onChange={() => setOptoutMonthlySummary(!optoutMonthlySummary)}
                />
              }
              label={t('notificationSettings.monthly-summary')}
            />
          </Grid>*/}

          {features.includes('chat') && (
            <Grid item pl={4}>
              <FormControlLabel
                control={
                  <Switch checked={optoutChatMessages} onChange={() => setOptoutChatMessages(!optoutChatMessages)} />
                }
                label={t('notificationSettings.chat-messages')}
              />
            </Grid>
          )}
        </Grid>
      </ModalPaper>
    </DialogContent>
  )
}

export default withSimpleModal(NotificationModal)
